import React from "react";
import PropTypes from "prop-types";

import "./DashboardPanelFooter.scss";
import { combineCssClasses } from "../../../../utils/utils";

const DashboardPanelFooter = ({ children, className }) => {
  const cssClasses = combineCssClasses("dashboard__panel-footer", className);
  return <div className={cssClasses}>{children}</div>;
};

DashboardPanelFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

export default DashboardPanelFooter;
