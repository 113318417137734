import React, { useState, useEffect, useContext } from "react";

import InfoPanel from "../../Layout/InfoPanel";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-regular-svg-icons";

import "./ObjectivesOverview.scss";
import { faStumbleuponCircle } from "@fortawesome/free-brands-svg-icons";
import { Link, useRouteMatch } from "react-router-dom";
import RoadMapContext from "../../../context/RoadMapContext";
import InfoPanelIcon from "../../Layout/InfoPanel/InfoPanelIcon";

const RoadmapOverview = ({ data }) => {
  const routeMatch = useRouteMatch();
  const { phases } = useContext(RoadMapContext);
  const [currentPhaseName, setCurrentPhaseName] = useState(null);

  useEffect(() => {
    const [current] = phases.filter(phase => phase.currentPhase === 1);
    if(current !== undefined) setCurrentPhaseName(current.phaseName);
  }, [phases]);

  if(currentPhaseName !== null)

    return (
      <section className="grid-4-col p2t">
        <InfoPanel
          Icon={<InfoPanelIcon icon={faMinusCircle} background="grey" />}
          TopContent={
            <>
              <span className="text--light m1b">Not started</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/objectives/list?filter=not-started&phase=${currentPhaseName}`}
                >
                  {data.notStartedObjectives}
                </Link>
              </span>
            </>
          }
        ></InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faStumbleuponCircle} background="orange" />}
          TopContent={
            <>
              <span className="text--light m1b">In Progress</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/objectives/list?filter=in-progress&phase=${currentPhaseName}`}
                >
                  {data.inProgressObjectives}
                </Link>
              </span>
            </>
          }
        ></InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faCheckCircle} background="green" />}
          TopContent={
            <>
              <span className="text--light m1b">Completed</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/objectives/list?filter=completed&phase=${currentPhaseName}`}
                >
                  {data.completedObjectives}
                </Link>
              </span>
            </>
          }
        ></InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faTimesCircle} background="red" />}
          TopContent={
            <>
              <span className="text--light m1b">Blocked</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/objectives/list?filter=blocked&phase=${currentPhaseName}`}
                >
                  {data.blockedOjectives}
                </Link>
              </span>
            </>
          }
        ></InfoPanel>
      </section>
    );
  
  if(currentPhaseName === null)
  return(
    <>
      <center>
        To view the objectives, the current phase need to be set.
      </center>
    </>
  );
};

export default RoadmapOverview;
