import React from "react";
import PropTypes from "prop-types";

import "./ProgressPanel.scss";
import ProgressChart from "../ProgressChart/ProgressChart";

const ProgressPanel = ({ title, subTitle, chart }) => {
  return (
    <div className="progress-panel">
      <div className="progress-panel__left">
        <p className="progress-panel__title">{title}</p>
        <p className="progress-panel__subtitle">{subTitle}</p>
      </div>
      <div className="progress-panel__right">
        <ProgressChart progress={chart.value} color={chart.color} />
      </div>
    </div>
  );
};

ProgressPanel.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  chart: PropTypes.shape({
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })
};

export default ProgressPanel;
