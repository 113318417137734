import React, { useEffect, useState } from "react";

import "./Actions.scss";
import DashboardPanel from "../../Layout/DashboardPanel";
import { Route, NavLink, Switch } from "react-router-dom";
import ActionsByPhase from "./ActionsByPhase";
import ActionsList from "./ActionsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faChartLine,
  faColumns
} from "@fortawesome/free-solid-svg-icons";
import DashboardPanelFooter from "../../Layout/DashboardPanel/DashboardPanelFooter/DashboardPanelFooter";
import axios from "axios";
import Loader from "../../Loader";
import ActionsGantt from "./ActionsGantt";
import useSearchParams from "../../../hooks/useSearchParams";

const Actions = ({ history, match }) => {
  const { forType, forId } = useSearchParams();
  const { roadMapId } = match.params;

  const [actions, setActions] = useState(null);
  const [actionsFor, setActionsFor] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    getAllData(roadMapId, token, forType, forId)
      .then(({ _actions, _actionsForTitle }) => {
        setActionsFor(_actionsForTitle);
        setActions(_actions);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        // history.push("/login");
      });
  }, [history, roadMapId, forType, forId]);

  if (loading) return <Loader>Loading actions ...</Loader>;
  return (
    <DashboardPanel>
      <Switch>
        <Route exact path={`${match.path}`}>
          {routeProps => (
            <ActionsList
              {...routeProps}
              actions={actions}
              actionsFor={actionsFor}
            />
          )}
        </Route>
        <Route exact path={`${match.path}/chart`}>
          {routeProps => (
            <ActionsGantt
              {...routeProps}
              actions={actions}
              actionsFor={actionsFor}
            />
          )}
        </Route>
        <Route exact path={`${match.path}/by-phase`}>
          {routeProps => (
            <ActionsByPhase
              {...routeProps}
              actions={actions}
              actionsFor={actionsFor}
            />
          )}
        </Route>
      </Switch>
      <DashboardPanelFooter>    

        <NavLink
          exact
          to={`${match.url}`}
          className="m2r lighter-grey"
          activeClassName="text-dark-grey active"
        >
          <FontAwesomeIcon icon={faList} size="2x" />
        </NavLink>

        <NavLink
          exact
          to={`${match.url}/by-phase`}
          className="lighter-grey"
          activeClassName="text-dark-grey active"
        >
          <FontAwesomeIcon icon={faColumns} size="2x" />
        </NavLink>
            &nbsp; &nbsp; &nbsp;
        <NavLink
          exact
          to={`${match.url}/chart`}
          className="m2r lighter-grey"
          activeClassName="text-dark-grey active"
        >
          <FontAwesomeIcon icon={faChartLine} size="2x" />
        </NavLink>

      </DashboardPanelFooter>
    </DashboardPanel>
  );
};

function getAllData(roadMapId, token, forType, forId) {
  const actionsForFn =
    forType === "strategicarea"
      ? getStrategicAreaDetails(roadMapId, forId, token)
      : forType === "tacticgroup"
      ? getTacticGroupDetails(roadMapId, forId, token)
      : forType === "objective"
      ? getObjectiveDetails(roadMapId, forId, token)
      : Promise.resolve(null);

  return Promise.all([
    getActions(roadMapId, token, forType, forId),
    actionsForFn
  ]).then(([_actions, _actionsFor]) => {
    const _actionsForTitle =
      forType === "strategicarea"
        ? `Actions limited to Strategic Area - ${_actionsFor.areaDescription}.`
        : forType === "tacticgroup"
        ? `Actions limited to Tactic - ${_actionsFor.tacticGroupDescription}.`
        : forType === "objective"
        ? `Actions limited to Objective - ${_actionsFor.objectiveDescription}.`
        : null;

    return {
      _actions,
      _actionsForTitle
    };
  });
}

function getActions(roadMapId, token, forType, forId) {
  //TODO: This needs to be in a helper
  var qs = null;
  switch (forType) {
    case "strategicarea":
      qs = `?saId=${forId}`;
      break;
    case "tacticgroup":
      qs = `?tgId=${forId}`;
      break;
    case "objective":
      qs = `?oId=${forId}`;
      break;
    default:
      qs = "";
      break;
  }

  return axios
    .get(
      process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/actions${qs}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(response => response.data);
}

function getStrategicAreaDetails(roadMapId, strategicId, token) {
  //TODO: This needs to be in a helper AND we should get the objective by id rather than all and filtering
  if (!strategicId) return null;

  return axios
    .get(
      process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/strategicarea`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(response => {
      const [strategic] = response.data.filter(
        sa => sa.areaId === strategicId
      );
      return strategic || null;
    });
}

function getTacticGroupDetails(roadMapId, tacticGroupId, token) {
  //TODO: This needs to be in a helper AND we should get the tactic by id rather than all and filtering
  if (!tacticGroupId) return null;

  return axios
    .get(process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/tactics`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(response => {
      const [tacticGroup] = response.data.PC13.filter(
        tg => tg.tacticGroupId === tacticGroupId
      );
      return tacticGroup || null;
    });
}

function getObjectiveDetails(roadMapId, objectiveId, token) {
  //TODO: This needs to be in a helper AND we should get the objective by id rather than all and filtering
  if (!objectiveId) return null;

  return axios
    .get(
      process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/objectives`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(response => {
      const [objective] = response.data.filter(
        o => o.objectiveId === objectiveId
      );
      return objective || null;
    });
}

export default Actions;
