import React from "react";
import PropTypes from "prop-types";

import "./Loader.scss";
import { combineCssClasses } from "../../utils/utils";

const Loader = ({ children, centered }) => {
  const cssClasses = combineCssClasses(
    "loader",
    centered ? "fully-centered" : null
  );
  return (
    <div className={cssClasses}>
      {/* //   <div className="bars">
    //     <div className="bar"></div>
    //     <div className="bar"></div>
    //     <div className="bar"></div>
    //     <div className="bar"></div>
    //     <div className="bar"></div>
    //   </div> */}
      <div>{children}</div>
    </div>
  );
};

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Loader;
