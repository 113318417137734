import React from "react";

import "./Popover.scss";
import { combineCssClasses } from "../../../utils/utils";

const Popover = ({ children, pos }) => {
  if (!pos || !pos.left) return null;

  // 420px is max width + arrow width
  const cPos = pos.right + 420 > document.body.clientWidth;

  const popoverClasses = combineCssClasses(
    "popover",
    `popover--${cPos ? "left" : "right"}`
  );

  return (
    <div
      className={popoverClasses}
      style={{
        left: pos.left + (cPos ? 0 : pos.width),
        top: pos.top + pos.height / 2 + document.body.scrollTop
      }}
    >
      {children}
    </div>
  );
};

export default Popover;
