import React from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core";
import "./InfoPanelIcon.scss";

const InfoPanelIcon = ({ icon, background }) => {
  return (
    <div className={`info-panel__icon bg-${background}`}>
      <FontAwesomeIcon
        icon={icon}
        className="fa-3x"
        style={{
          opacity: 0.6,
          transform: "scale(0.8)"
        }}
      />
    </div>
  );
};

InfoPanelIcon.propTypes = {
  icon: PropTypes.objectOf(icon)
};

export default InfoPanelIcon;
