import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";

import "./RoadmapOverview.scss";
import InfoPanel from "../../Layout/InfoPanel";
import { faTrafficLight, faBullseye } from "@fortawesome/free-solid-svg-icons";
import {
  faTimesCircle,
  faCheckSquare,
  faCalendarAlt,
  faClock
} from "@fortawesome/free-regular-svg-icons";
import InfoPanelDetail from "../../Layout/InfoPanel/InfoPanelDetail";
import { Link, useRouteMatch } from "react-router-dom";
import RoadMapContext from "../../../context/RoadMapContext";
import InfoPanelIcon from "../../Layout/InfoPanel/InfoPanelIcon";

const RoadmapOverview = ({ data }) => {
  const routeMatch = useRouteMatch();
  const { phases } = useContext(RoadMapContext);
  const [currentPhaseName, setCurrentPhaseName] = useState(null);

  useEffect(() => {
    const [current] = phases.filter(phase => phase.currentPhase === 1);
    if(current !== undefined) setCurrentPhaseName(current.phaseName);
  }, [phases]);

  if(currentPhaseName !== null)

    return (
      <section className="grid-4-col">
        <InfoPanel
          Icon={
            <InfoPanelIcon
              icon={faTrafficLight}
              background={
                data.overallStatus.toLowerCase() === "critical"
                  ? "red"
                  : data.overallStatus.toLowerCase() === "warning"
                  ? "orange"
                  : data.overallStatus.toLowerCase() === "all good"
                  ? "green"
                  : "grey"
              }
            />
          }
          TopContent={
            <>
              <span className="text--light m1b">Roadmap Status</span>
              <span className="text--large">{data.overallStatus}</span>
            </>
          }
        >
          <Link
            to={`${routeMatch.url}/actions?filter=blocked&phase=${currentPhaseName}`}
          >
            <p className="text--light">
              <span className="text--bold p1r">
                {data.statusImpactingActions}
              </span>{" "}
              or more actions overdue/blocked
            </p>
          </Link>
        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faBullseye} background="orange" />}
          TopContent={
            <>
              <span className="text--light m1b">Objectives done</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/objectives/list?filter=completed&phase=${currentPhaseName}`}
                >
                  {data.objectivesCompleted}
                </Link>
                /
                <Link
                  to={`${routeMatch.url}/objectives/list?phase=${currentPhaseName}`}
                >
                  {data.totalObjectives}
                </Link>
              </span>
            </>
          }
        >
          <Link
            to={`${routeMatch.url}/objectives/list?filter=blocked&phase=${currentPhaseName}`}
          >
            <InfoPanelDetail
              icon={faTimesCircle}
              text="Objectives blocked"
              value={data.blockedOjectives}
            />
          </Link>
        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faCheckSquare} background="green" />}
          TopContent={
            <>
              <span className="text--light m1b">Actions done</span>
              <span className="text--large">
                <Link
                  to={`${routeMatch.url}/actions?filter=completed&phase=${currentPhaseName}`}
                >
                  {data.completedActions}
                </Link>
                /
                <Link to={`${routeMatch.url}/actions?phase=${currentPhaseName}`}>
                  {data.totalActions}
                </Link>
              </span>
            </>
          }
        >
          <Link
            to={`${routeMatch.url}/actions?filter=blocked&phase=${currentPhaseName}`}
          >
            <InfoPanelDetail
              icon={faTimesCircle}
              text="Actions blocked"
              value={data.blockedActions}
            />
          </Link>
        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faCalendarAlt} background="blue" />}
          TopContent={
            <>
              <span className="text--light m1b">End of phase</span>
              <span className="text--large">
                {format(new Date(data.endOfCurrentPhase), "d MMM yy")}
              </span>
            </>
          }
        >
          <InfoPanelDetail
            icon={faClock}
            text="Current phase"
            value={data.nameOfCurrentPhase}
          />
        </InfoPanel>
      </section>
    );
  
  if(currentPhaseName === null) 
    return(
      <section className="grid-4-col">
        <InfoPanel
          Icon={
            <InfoPanelIcon
              icon={faTrafficLight}
              background={
                data.overallStatus.toLowerCase() === "critical"
                  ? "red"
                  : data.overallStatus.toLowerCase() === "warning"
                  ? "orange"
                  : data.overallStatus.toLowerCase() === "all good"
                  ? "green"
                  : "grey"
              }
            />
          }
          TopContent={
            <>
              <span className="text--light m1b">Roadmap Status</span>
              <span className="text--large">{data.overallStatus}</span>
            </>
          }
        >
          
          <p className="text--light">
            Current phase has not been set yet.
          </p>
        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faBullseye} background="orange" />}
          TopContent={
            <>
              <span className="text--light m1b">Objectives done</span>
              <span className="text--large">
                -
              </span>
            </>
          }
        >
          <InfoPanelDetail
            icon={faTimesCircle}
            text="Current phase has not been set yet."
            value={null}
          />

        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faCheckSquare} background="green" />}
          TopContent={
            <>
              <span className="text--light m1b">Actions done</span>
              <span className="text--large">
                -
              </span>
            </>
          }
        >
          <InfoPanelDetail
            icon={faTimesCircle}
            text="Current phase has not been set yet."
            value={null}
          />
        </InfoPanel>

        <InfoPanel
          Icon={<InfoPanelIcon icon={faCalendarAlt} background="blue" />}
          TopContent={
            <>
              <span className="text--light m1b">End of phase</span>
              <span className="text--large">
                -
              </span>
            </>
          }
        >
          <InfoPanelDetail
            icon={faClock}
            text="Current phase has not been set yet"
            value={data.nameOfCurrentPhase}
          />
        </InfoPanel>
      </section>
    );
};

RoadmapOverview.propTypes = {
  data: PropTypes.shape({
    overallStatus: PropTypes.string,
    statusImpactingActions: PropTypes.number,
    objectivesCompleted: PropTypes.number,
    totalObjectives: PropTypes.number,
    blockedOjectives: PropTypes.number,
    totalActions: PropTypes.number,
    completedActions: PropTypes.number,
    blockedActions: PropTypes.number,
    nameOfCurrentPhase: PropTypes.string,
    endOfCurrentPhase: PropTypes.string
  })
};

export default RoadmapOverview;
