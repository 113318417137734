import React, { useContext } from "react";
import CenteredPage from "../Layout/CenteredPage";
import "./RoadmapSelector.scss";
import UserContext from "../../context/UserContext";
import { Redirect, Link } from "react-router-dom";

const RoadmapSelector = () => {
  const {
    currentUser: { roadMaps }
  } = useContext(UserContext);

  if (roadMaps.length === 1)
    return <Redirect to={`roadmap/${roadMaps[0].rmId}/dashboard`} />;

  return (
    <CenteredPage>
      <h1 className="m2b">Available Roadmaps</h1>
      <div className="p3 flex-column align-items-centre">
        {roadMaps.map(roadMap => {
          return (
            <Link
              key={roadMap.rmId}
              to={`/roadmap/${roadMap.rmId}/dashboard`}
              className="light"
            >
              <p className="text--large p1 align-centre">{roadMap.rmDesc}</p>
            </Link>
          );
        })}
      </div>
    </CenteredPage>
  );
};

export default RoadmapSelector;
