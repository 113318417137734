import { useLocation } from "react-router-dom";
import { useState, useMemo, useEffect } from "react";

// NOTE: This currently triggers a double render (maybe memoize location.search and check in the effect that they're not the same)

const useSearchParams = () => {
  const location = useLocation();

  const initialParams = useMemo(() => {
    const params = [...new URLSearchParams(location.search)];
    if (params.length === 0) return {};

    return params.reduce((acc, curr) => {
      const [key, val] = curr;
      acc[key] = val;
      return acc;
    }, {});
  }, [location.search]);

  useEffect(() => {
    const params = [...new URLSearchParams(location.search)];
    if (params.length === 0) setSearchParams({});

    setSearchParams(
      params.reduce((acc, curr) => {
        const [key, val] = curr;
        acc[key] = val;
        return acc;
      }, {})
    );
  }, [location.search]);

  const [searchParams, setSearchParams] = useState(initialParams);

  return searchParams;
};
export default useSearchParams;
