import React from "react";

import "./TableHeader.scss";
import { combineCssClasses } from "../../../../utils/utils";

const TableHeader = ({ children, columns, className }) => {
  return (
    <div
      className={combineCssClasses("table__row table__header", className)}
      style={{ gridTemplateColumns: columns }}
    >
      {children}
    </div>
  );
};

export default TableHeader;
