import React, { useContext, useState } from "react";

import "./ObjectivesByPhase.scss";
import DashboardPanelHeader from "../../../Layout/DashboardPanel/DashboardPanelHeader";
import StatusPill from "../../../Layout/StatusPill";
import FiltersPanel from "../../FiltersPanel";
import RowFlowLayout from "../../../Layout/RowFlowLayout/RowFlowLayout";
import Loader from "../../../Loader";
import RoadMapContext from "../../../../context/RoadMapContext";
import PhasedLayout from "../../../Layout/PhasedLayout";
import PhasedLayoutHeader from "../../../Layout/PhasedLayout/PhasedLayoutHeader/PhasedLayoutHeader";
import PhasedLayoutContent from "../../../Layout/PhasedLayout/PhasedLayoutContent";
import PhasedLayoutColumn from "../../../Layout/PhasedLayout/PhasedLayoutColumn";
import Popover from "../../../Layout/Popover/Popover";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useHistory, useLocation } from "react-router-dom";
import AlertBox from "../../../Layout/AlertBox";

const Header = () => (
  <DashboardPanelHeader className="flex">
    <h1 className="dashboard__panel-title">Objectives by phase</h1>
    <FiltersPanel showPhases={false}></FiltersPanel>
  </DashboardPanelHeader>
);
const Loading = () => (
  <>
    <Header />
    <Loader>Loading objectives ...</Loader>
  </>
);

const ObjectivesByPhase = ({ objectives, obFor }) => {
  const { phases } = useContext(RoadMapContext);
  const [pos, setPos] = useState(null);
  const [activeObjective, setActiveObjective] = useState(null);
  const { phase = null, filter = null } = useSearchParams();
  const location = useLocation();
  const history = useHistory();

  const clearOfType = () => {
    const params = [
      filter ? `filter=${filter}` : null,
      phase ? `phase=${phase}` : null
    ].filter(e => e !== null);
    history.push(
      `${location.pathname}${params.length > 0 ? "?" : ""}${params.join("&")}`
    );
    history.go(0)
  };
  
  const ObjectivesFor = () => {
    if (obFor)
      return (
        <AlertBox>
          <h4 className="flex-row align-items-centre">
            <span className="flex-grow-1">{obFor}</span>
            <button className="btn btn--alert" onClick={clearOfType}>
              Clear
            </button>
          </h4>
        </AlertBox>
      );
  
    return null;
  };

  if (!phases || !objectives) return <Loading />;

  const showPopover = (objective, e) => {
    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    setPos({ left, top, width, height, right: left + width });
    setActiveObjective(objective);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveObjective(null);
  };

  return (
    <>
      <Header />
      <ObjectivesFor />
      <PhasedLayout>
        {phases.map(phase => {
          return (
            <PhasedLayoutColumn key={phase.phaseId}>
              <PhasedLayoutHeader phase={phase} />
              <PhasedLayoutContent>
                <RowFlowLayout columnCount={3} reversed={true}>
                  {objectives.map(objective => {
                    return objective.phaseId === phase.phaseId ? (
                      <StatusPill
                        className="help"
                        onMouseEnter={showPopover.bind(null, objective)}
                        onMouseLeave={hidePopover}
                        key={objective.objectiveId}
                        status={objective.status}
                      >
                        {objective.objectiveId}
                      </StatusPill>
                    ) : null;
                  })}
                </RowFlowLayout>
              </PhasedLayoutContent>
            </PhasedLayoutColumn>
          );
        })}
        <Popover pos={pos}>
          <p className="text--small">
            {activeObjective && activeObjective.objectiveDescription}
          </p>
        </Popover>
      </PhasedLayout>
    </>
  );
};

export default ObjectivesByPhase;
