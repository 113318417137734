import React from "react";

const PhaseSelector = ({ phases, selectedPhase, changePhase }) => {
  return (
    <div className="m1b flex align-centre">
      <div>Phase:</div>
      <div className="m2l">
        <select
          value={selectedPhase || ""}
          onChange={changePhase}
          onBlur={changePhase}
        >
          <option value="">All</option>
          {phases.map(phase => (
            <option key={phase.phaseId} value={phase.phaseName}>
              {phase.phaseName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default PhaseSelector;
