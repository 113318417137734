import React from "react";
import PropTypes from "prop-types";

import "./ProgressChart.scss";
import { combineCssClasses } from "../../../utils/utils";

const ProgressChart = ({ progress = 0, color = "black", size }) => {
  const chartContainerClasses = combineCssClasses(
    "chart charts-container",
    size ? `chart--${size}` : ""
  );
  const chartClasses = combineCssClasses(
    "pie-wrapper style-2",
    `progress-${progress}`
  );
  return (
    <div className={chartContainerClasses}>
      <div className={chartClasses}>
        <span className="label">
          {progress}
          <span className="smaller">%</span>
        </span>
        <div className="pie">
          <div className={`left-side half-circle bdr-${color}`}></div>
          <div className={`right-side half-circle bdr-${color}`}></div>
        </div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};

ProgressChart.propTypes = {
  progress: PropTypes.number.isRequired,
  size: PropTypes.string
};

export default ProgressChart;
