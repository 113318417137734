import React from "react";
import PropTypes from "prop-types";

import "./TableCell.scss";

const getClassNames = (baseClasses, className, align) => {
  const classList = [
    ...(baseClasses || "").split(),
    ...(className || "").split(),
    align === "right"
      ? "justify-content-end text-align--right"
      : align === "centre"
      ? "justify-content-centre text-align--centre"
      : ""
  ].filter(e => !!e);
  return classList.length > 0 ? `${classList.join(" ")}` : "";
};

const TableCell = ({ children, align, className }) => {
  return (
    <div className={getClassNames("table__cell", className, align)}>
      {children}
    </div>
  );
};

TableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  align: PropTypes.string,
  className: PropTypes.string
};

export default TableCell;
