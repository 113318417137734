export function combineCssClasses(defaults, combineWith) {
  return `${defaults}${combineWith ? " " : ""}${combineWith || ""}`;
}

export function getPhaseByName(phases, phaseName) {
  if (!phaseName) return null;

  const [p] = phases.filter(phase => {
    return phase.phaseName.toLowerCase() === phaseName.toLowerCase();
  });
  return p;
}

export function combine(vals, seperator) {
  return vals.filter(v => v !== null && v !== undefined).join(seperator);
}
