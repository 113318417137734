import React, { useEffect, useState } from "react";

import "./Tactics.scss";
import DashboardPanel from "../../Layout/DashboardPanel";
import DashboardPanelHeader from "../../Layout/DashboardPanel/DashboardPanelHeader";
import TacticSummary from "./TacticSummary/TacticSummary";
import Loader from "../../Loader";

import { NavLink, Switch, Route } from "react-router-dom";
import axios from "axios";
import TacticDetails from "./TacticDetails";

const Header = () => {
  return (
    <DashboardPanelHeader className="flex">
      <h1 className="dashboard__panel-title">Tactics by strategic area</h1>
    </DashboardPanelHeader>
  );
};

const Tactics = ({ match, location, history }) => {
  const { roadMapId } = match.params;
  const [loading, setLoading] = useState(true);
  const [strategicAreas, setStrategicAreas] = useState(null);
  const [tactics, setTactics] = useState(null);

  /* TODO: Refactor so we only pass the filtered tactics in (route to HOC maybe?) */
  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/tactics`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        setStrategicAreas(response.data.PC12);
        setTactics(response.data.PC13);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        history.push("/login");
      });
  }, [history, roadMapId]);

  useEffect(() => {
    if (strategicAreas === null) return;

    if (match.url === location.pathname) {
      const [firstArea] = strategicAreas;

      history.push(`${location.pathname}/${firstArea.areaId}`);
    }
  }, [match, history, location, strategicAreas]);

  if (loading)
    return (
      <DashboardPanel>
        <Header />
        <Loader>Loading ...</Loader>
      </DashboardPanel>
    );

  return (
    <DashboardPanel>
      <Header />

      <div className="flex">
        <div className="tactic-summary__list">
          {strategicAreas.map((strategicArea, idx) => {
            return (
              <NavLink
                key={idx}
                to={`${match.url}/${strategicArea.areaId}`}
                className="tactic-summary__list-item"
                activeClassName="tactic-summary__list-item--active"
              >
                <TacticSummary
                  title={strategicArea.areaDescription}
                  value={strategicArea.percentageComplete}
                  active={
                    location.pathname === `${match.url}/${strategicArea.areaId}`
                  }
                />
              </NavLink>
            );
          })}
        </div>
        <div className="flex-grow-1">
          <Switch>
            <Route path={`${match.path}/:id`} component={TacticDetails}>
              {routeProps => (
                <TacticDetails {...routeProps} tactics={tactics} />
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </DashboardPanel>
  );
};

export default Tactics;
