import React, { useContext } from "react";
import {
  faColumns,
  faBullseye,
  faShareAlt
  //,faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import { faStripeS } from "@fortawesome/free-brands-svg-icons";
import LeftNavItem from "./LeftNavItem";

import "./LeftNav.scss";
import { useRouteMatch } from "react-router-dom";

import RoadMapContext from "../../../context/RoadMapContext";
import BrandLogo from "./BrandLogo/BrandLogo";
// import Icon from "../Icon/Icon";

const navItems = [
  { icon: faColumns, text: "Dashboard", path: "", index: 1, exact: true },
  // {
  //   icon: faExclamationTriangle,
  //   text: "Situation",
  //   path: "situation",
  //   index: 10
  // },
  {
    icon: faBullseye,
    text: "Goals / Objectives",
    path: "objectives",
    index: 20
  },
  {
    icon: faStripeS,
    text: "Strategic areas",
    path: "strategic-areas",
    index: 30
  },
  { icon: faShareAlt, text: "Tactics", path: "tactics", index: 40 },
  {
    icon: faCheckSquare,
    text: "Actions",
    path: "actions",
    index: 50
  }
].sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0));

const LeftNav = () => {
  const roadMapContext = useContext(RoadMapContext);

  const routeMatch = useRouteMatch();
  return (
    <nav className="leftnav">
      <div className="leftnav__brand">
        <BrandLogo src={roadMapContext.details.rmLogo} />
      </div>
      {navItems.map(navItem => (
        <LeftNavItem
          key={navItem.index}
          icon={navItem.icon}
          text={navItem.text}
          path={`${routeMatch.url}${navItem.path ? "/" : ""}${navItem.path}`}
          exact={navItem.exact || false}
        />
      ))}
      {/* <Icon iconName="actions" colour="orange" />
      <Icon iconName="blocked" colour="orange" />
      <Icon iconName="completed" colour="orange" />
      <Icon iconName="dashboard" colour="orange" />
      <Icon iconName="end-of-phase" colour="orange" />
      <Icon iconName="goals-and-objectives" colour="orange" />
      <Icon iconName="in-progress" colour="orange" />
      <Icon iconName="not-started" colour="orange" />
      <Icon iconName="on-time" colour="orange" />
      <Icon iconName="situation" colour="orange" />
      <Icon iconName="strategic-areas" colour="orange" />
      <Icon iconName="tactics" colour="orange" /> */}
    </nav>
  );
};

export default LeftNav;
