import React from "react";

import "./TableRow.scss";

const getClassNames = (baseClasses, className, align) => {
  const classList = [
    ...(baseClasses || "").split(),
    ...(className || "").split(),
    align === "right"
      ? "justify-content-end"
      : align === "centre"
      ? "justify-content-centre"
      : ""
  ].filter(e => !!e);
  return classList.length > 0 ? `${classList.join(" ")}` : "";
};

const TableRow = ({ children, columns, className }) => {
  return (
    <div
      className={getClassNames("table__row", className)}
      style={{ gridTemplateColumns: columns }}
    >
      {children}
    </div>
  );
};

export default TableRow;
