import React, { useContext, useEffect, useState } from "react";

import "./Situation.scss";
import DashboardPanel from "../../Layout/DashboardPanel";
import DashboardPanelHeader from "../../Layout/DashboardPanel/DashboardPanelHeader";
import DashboardPanelFooter from "../../Layout/DashboardPanel/DashboardPanelFooter";
import TableLayout from "../../Layout/TableLayout";
import TableHeader from "../../Layout/TableLayout/TableHeader/TableHeader";
import TableCell from "../../Layout/TableLayout/TableCell";
import TableRow from "../../Layout/TableLayout/TableRow/TableRow";
import ProgressPanel from "../../Layout/ProgressPanel/ProgressPanel";
import ProgressChart from "../../Layout/ProgressChart/ProgressChart";
import axios from "axios";
import Loader from "../../Loader";
import RoadMapContext from "../../../context/RoadMapContext";
import useSearchParams from "../../../hooks/useSearchParams";
import { Link } from "react-router-dom";
import format from "date-fns/format";

const columnsTemplate = "1fr repeat(3, 100px";

const Situation = ({ history, match }) => {
  const qs = useSearchParams();
  const { roadMapId } = match.params;
  const { phases } = useContext(RoadMapContext);
  const [loading, setLoading] = useState(true);
  const [situationTotals, setSituationTotals] = useState(null);
  const [situationDetails, setSituationDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [empty, setEmpty] = useState(false);

  const date = phases.map( (item) => {
    return item.phaseStartDate
  });

  const Header = () => (
    <DashboardPanelHeader className="flex">
      <h1 className="dashboard__panel-title">Your digital assesment results</h1>
      <div className="situation__header-info">
        <p className="">{ (date[currentPage] !== null && date[currentPage] !== undefined)? 'As of' : '' }</p>
        <p className="text--large">{ (date[currentPage] !== null && date[currentPage] !== undefined)? format(new Date(date[currentPage]), "MMMM d, yyyy") : '' }</p>
      </div>
    </DashboardPanelHeader>
  );

  useEffect(() => {
    
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    var dateParam = (history.location.search === '')
      ? '?date='+ format(new Date(date[currentPage]), "yyyy-MM-dd") 
      : `?date=${qs.date}`;

    setTimeout(() => {

      const fetch = async () => {
        try {
          const { data } = 
          await axios.get(
            process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/situation/${dateParam}`,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          )

          const { currentlyUse, importance, ability } = data.PC7;
          
          setSituationTotals({ currentlyUse, importance, ability });
          setSituationDetails(data.PC8);
          setLoading(false);
  
        } catch (err) {
          console.error(err);
        }
      };
      fetch();

    }, 200);

  }, [history, roadMapId]);


  if (loading || !phases) {

    if ( situationDetails === null || situationTotals === null )
      return (
        <DashboardPanel>
          <Header />
          <Loader>No Records Found</Loader>
          <DashboardPanelFooter>
            <div className="flex justify-content-centre text--light">
              <Link to={{pathname: '/roadmap/'+roadMapId+'/dashboard'}}>
                back
              </Link>
            </div>
          </DashboardPanelFooter>
        </DashboardPanel>
      );
    
    return (
        <DashboardPanel>
          <Header />
          <Loader>Loading ...</Loader>
        </DashboardPanel>
      );
  }
    

  
  if (empty || !phases)
    return (
      <DashboardPanel>
        <Header />
        <Loader>No Records Found</Loader>
        <DashboardPanelFooter>
          <div className="flex justify-content-centre text--light">
            <Link onClick={
              () =>{
                history.goBack();
                window.location.reload();
              }
              }>
              back
            </Link>
          </div>
        </DashboardPanelFooter>
      </DashboardPanel>
  );

  return (
    <DashboardPanel>
      <Header />

      <div className="situation__overview">
        <ProgressPanel
          title="Currently use"
          subTitle="Aggregate score based on the how well you currently use tactics
                across the strategic areas"
          chart={{
            color: getStatusColour(situationTotals.currentlyUse),
            value: situationTotals.currentlyUse
          }}
        ></ProgressPanel>
        <ProgressPanel
          title="Importance"
          subTitle="Aggregate score based on the how important you rate the strategic areas"
          chart={{
            color: getStatusColour(situationTotals.importance),
            value: situationTotals.importance
          }}
        ></ProgressPanel>
        <ProgressPanel
          title="Ability"
          subTitle="Aggregate score based on the rating of how able you are to deliver the tactics. Based on available resources."
          chart={{
            color: getStatusColour(situationTotals.ability),
            value: situationTotals.ability
          }}
        ></ProgressPanel>
      </div>

      <div className="flex flex-grow flex-grow-columns">
        <TableLayout className="m2r">
          <TableHeader columns={columnsTemplate}>
            <TableCell>Description</TableCell>
            <TableCell align="centre">Current use</TableCell>
            <TableCell align="centre">Importance</TableCell>
            <TableCell align="centre">Ability</TableCell>
          </TableHeader>
          {situationDetails
            .slice(0, Math.floor(situationDetails.length / 2))
            .map((row, i) => {
              return (
                <TableRow key={i} columns={columnsTemplate} className="p1t p1b">
                  <TableCell align="left">{row.strategicArea}</TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.currentlyUse}
                      color={getStatusColour(row.currentlyUse)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.importance}
                      color={getStatusColour(row.importance)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.ability}
                      color={getStatusColour(row.ability)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableLayout>
        <TableLayout className="m2l">
          <TableHeader columns={columnsTemplate}>
            <TableCell>Description</TableCell>
            <TableCell align="centre">Current use</TableCell>
            <TableCell align="centre">Importance</TableCell>
            <TableCell align="centre">Ability</TableCell>
          </TableHeader>
          {situationDetails
            .slice(Math.floor(situationDetails.length / 2))
            .map((row, i) => {
              return (
                <TableRow key={i} columns={columnsTemplate} className="p1t p1b">
                  <TableCell align="left">{row.strategicArea}</TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.currentlyUse}
                      color={getStatusColour(row.currentlyUse)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.importance}
                      color={getStatusColour(row.importance)}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="centre">
                    <ProgressChart
                      progress={row.ability}
                      color={getStatusColour(row.ability)}
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableLayout>
      </div>
      <DashboardPanelFooter>
        <div className="flex justify-content-centre text--light">
          { 
            currentPage-1 >= 0 ?
            <Link
              style={{color: 'green', fontWeight: '600'}} 
              to={{pathname: history.location.pathname, search: `?date=${format(new Date(date[currentPage-1]), "yyyy-MM-dd")}`}}
              onClick={ 
                () => {

                  const token = localStorage.getItem("successflow.roadmap.auth-token");

                  var dateParam = '?date='+format(new Date(date[currentPage-1]), "yyyy-MM-dd");
                  
                  setTimeout(() => {
                    const fetch = async () => {
                      try {
                        const { data } = 
                        await axios.get(
                          process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/situation/${dateParam}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          }
                        )
                        
                        const { currentlyUse, importance, ability } = data.PC7;
                        
                        setSituationTotals({ currentlyUse, importance, ability });
                        setSituationDetails(data.PC8);
                        setLoading(false);
                
                      } catch (err) {
                        console.error(err);
                        setEmpty(true);
                      }
                    };
                    fetch();
    
                    setCurrentPage(currentPage-1);

                  }, 200);
                } 
              }>
              &lt;&lt;
            </Link>

            :
            
            <div>
              &lt;&lt;
            </div>

          }

        <span className="p2l p2r">{ format(new Date(date[currentPage]), "MMMM d, yyyy") }</span>
          
          { 
            currentPage+1 < 6 ?
            <Link
              style={{color: 'green', fontWeight: '600'}} 
              to={{pathname: history.location.pathname, search: `?date=${format(new Date(date[currentPage+1]), "yyyy-MM-dd")}`}}
              onClick={ 
                () => {
                  console.log(currentPage+1, format(new Date(date[currentPage+1]), "yyyy-MM-dd"));
                  const token = localStorage.getItem("successflow.roadmap.auth-token");

                  var dateParam = '?date='+format(new Date(date[currentPage+1]), "yyyy-MM-dd");
                  
                  setTimeout(() => {
                    const fetch = async () => {
                      try {
                        const { data } = 
                        await axios.get(
                          process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/situation/${dateParam}`,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`
                            }
                          }
                        )
                        
                        const { currentlyUse, importance, ability } = data.PC7;
                        
                        setSituationTotals({ currentlyUse, importance, ability });
                        setSituationDetails(data.PC8);
                        setLoading(false);
                
                      } catch (err) {
                        console.error(err);
                        setEmpty(true);
                      }
                    };
                    fetch();
    
                    setCurrentPage(currentPage+1);

                  }, 200);
                } 
              }>
              &gt;&gt;
            </Link>

            :
            
            <div>
              &gt;&gt;
            </div>

          }
        </div>
      </DashboardPanelFooter>
      
    </DashboardPanel>
  );
};

const getStatusColour = percentage => {
  return percentage < 40
    ? "red"
    : percentage >= 40 && percentage < 70
    ? "orange"
    : "green";
};

export default Situation;
