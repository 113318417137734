import React from "react";

import "./TableLayout.scss";
import { combineCssClasses } from "../../../utils/utils";

const TableLayout = ({ children, className }) => {
  const classes = combineCssClasses("table", className);
  return <div className={classes}>{children}</div>;
};

export default TableLayout;
