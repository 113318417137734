import React, { useState, useEffect, useContext } from "react";

import "./ActionsList.scss";
import DashboardPanelHeader from "../../../Layout/DashboardPanel/DashboardPanelHeader";
import TableLayout from "../../../Layout/TableLayout";
import TableHeader from "../../../Layout/TableLayout/TableHeader/TableHeader";
import TableRow from "../../../Layout/TableLayout/TableRow/TableRow";
import TableCell from "../../../Layout/TableLayout/TableCell";
import StatusPill from "../../../Layout/StatusPill";
import Loader from "../../../Loader";

import format from "date-fns/format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import FiltersPanel from "../../FiltersPanel";
import useSearchParams from "../../../../hooks/useSearchParams";

import { getPhaseByName } from "../../../../utils/utils";
import RoadMapContext from "../../../../context/RoadMapContext";
import Popover from "../../../Layout/Popover/Popover";
import { useLocation, useHistory, Link } from "react-router-dom";
import AlertBox from "../../../Layout/AlertBox";

const columnsTemplate = "20px 1fr 100px 100px 100px 100px 100px 60px";

const ActionsList = props => {
  const { actions, actionsFor } = props;

  const { phases } = useContext(RoadMapContext);
  const [loading, setLoading] = useState(true);
  const [filteredActions, setFilteredActions] = useState(null);
  const [pos, setPos] = useState(null);
  const [activeAction, setActiveAction] = useState(null);

  const { filter = null, phase = null } = useSearchParams();
  const location = useLocation();
  const history = useHistory();

  const Header = () => (
    <DashboardPanelHeader className="flex">
      <h1 className="dashboard__panel-title">Actions overview</h1>
      <FiltersPanel currentPhase={phase} />
    </DashboardPanelHeader>
  );

  const clearOfType = () => {
    const params = [
      filter ? `filter=${filter}` : null,
      phase ? `phase=${phase}` : null
    ].filter(e => e !== null);

    history.push(
      `${location.pathname}${params.length > 0 ? "?" : ""}${params.join("&")}`
    );
  };

  const ActionsFor = () => {
    if (actionsFor)
      return (
        <AlertBox>
          <h4 className="flex-row align-items-centre">
            <span className="flex-grow-1">{actionsFor}</span>
            <button className="btn btn--alert" onClick={clearOfType}>
              Clear
            </button>
          </h4>
        </AlertBox>
      );

    return null;
  };

  useEffect(() => {
    if (filter === null && phase === null) {
      setFilteredActions(actions);
      setLoading(false);
      return;
    }

    const _currentPhase = getPhaseByName(phases, phase);
    const _actions = actions.filter(action => {
      return (
        (filter === null ||
          action.stDesc.toLowerCase().replace(" ", "-") === filter) &&
        (phase === null || action.phaseId === _currentPhase.phaseId)
      );
    });

    setFilteredActions(_actions);
    setLoading(false);
  }, [filter, actions, phase, phases]);

  if (loading)
    return (
      <>
        <Header />
        <Loader centered>Loading actions ...</Loader>
      </>
    );

  const showPopover = (action, e) => {
    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    setPos({ left, top, width, height, right: left + width });
    setActiveAction(action);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveAction(null);
  };

  return (
    <>
      <Header />
      <ActionsFor />
      <TableLayout>
        <TableHeader columns={columnsTemplate}>
          <TableCell></TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="centre">Started</TableCell>
          <TableCell align="centre">Due</TableCell>
          <TableCell align="centre">Objectives</TableCell>
          <TableCell align="centre">Owner</TableCell>
          <TableCell align="centre">Status</TableCell>
          <TableCell align="centre">Overdue</TableCell>
        </TableHeader>
        {filteredActions.map(action => {
          return (
            <TableRow key={action.action} columns={columnsTemplate}>
              <TableCell align="centre" className="text-theme">
                <div
                  className="help"
                  onMouseEnter={showPopover.bind(null, action)}
                  onMouseLeave={hidePopover}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </TableCell>
              <TableCell>{action.action}</TableCell>
              <TableCell align="centre">
                {format(new Date(action.planStart), "d MMM yy")}
              </TableCell>
              <TableCell align="centre">
                {format(new Date(action.planEnd), "d MMM yy")}
              </TableCell>
              <TableCell align="centre">
                {action.objectives 
                  ? <Link
                      to={`/roadmap/${props.match.params.roadMapId}/dashboard/objectives/list?forType=action&forId=${action.id}`}
                    >
                      {action.objectives}
                    </Link> 
                  : 'Not Set'}
              </TableCell>
              <TableCell align="centre">{action.owner}</TableCell>
              <TableCell>
                <StatusPill status={action.stDesc}>{action.stDesc}</StatusPill>
              </TableCell>
              <TableCell align="centre">
                {action.overdue > 0 && action.stDesc.toLowerCase() !== 'completed' ? (
                  <FontAwesomeIcon icon={faClock} color="red" />
                ) : null}
              </TableCell>
            </TableRow>
          );
        })}
      </TableLayout>
      <Popover pos={pos}>
        <p className="text--small">{activeAction && activeAction.narrative}</p>
      </Popover>
    </>
  );
};

export default ActionsList;
