import React from "react";
import PropTypes from "prop-types";

import "./DashboardPanelHeader.scss";
import { combineCssClasses } from "../../../../utils/utils";

const DashboardPanelHeader = ({ children, className }) => {
  return (
    <div className={combineCssClasses("dashboard__panel-header", className)}>
      {children}
    </div>
  );
};

DashboardPanelHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string
};

export default DashboardPanelHeader;
