import React, { useEffect, useState, useContext } from "react";
import DashboardPanel from "../../Layout/DashboardPanel";
import DashboardPanelFooter from "../../Layout/DashboardPanel/DashboardPanelFooter";
import { Switch, Route, NavLink } from "react-router-dom";

import ObjectivesByPhase from "./ObjectivesByPhase";
import ObjectivesList from "./ObjectivesList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderAll,
  faListUl,
  faColumns
} from "@fortawesome/free-solid-svg-icons";
import ObjectivesMatrix from "./ObjectivesMatrix";
import axios from "axios";
import RoadMapContext from "../../../context/RoadMapContext";
import useSearchParams from "../../../hooks/useSearchParams";
import { getPhaseByName } from "../../../utils/utils";

const Objectives = ({ history, match }) => {
  const { phases } = useContext(RoadMapContext);
  const { roadMapId } = match.params;

  const [objectives, setObjectives] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filteredObjectives, setFilteredObjectives] = useState(null);
  const [obFor, setObFor] = useState(null);
  const { filter = null, phase = null, forType, forId } = useSearchParams();

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    var qs = null;
    switch (forType) {
      case "strategicarea":
        qs = `?saId=${forId}`;
        break;
      case "action":
        qs = `?taId=${forId}`;
        break;
      default:
        qs = "";
        break;
    }

    setTimeout(() => {
      axios
        .get(
          process.env.REACT_APP_BASE_URL + `/roadmap/${roadMapId}/objectives${qs}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(response => {
          
          setObjectives(response.data);
          
          getForTitle(roadMapId, token, forType, forId)
          .then(({_obForTitle }) => {
            setObFor(_obForTitle);
          })
          .catch(e => {
            console.log(e);
          });

          setLoading(false);

        })
        .catch(err => {
          console.log(err);
          history.push("/login");
        });
    }, 1);
  }, [history, roadMapId]);

  useEffect(() => {
    if (!objectives) return;

    if (filter === null && phase === null) {
      setFilteredObjectives(objectives);
      setLoading(false);
      return;
    }

    const _currentPhase = getPhaseByName(phases, phase);
    const _objectives = objectives.filter(objective => {

      var filtered = null;
      
      if( filter === null && phase === null) {
        
        return;

      } else {

        if( objective.status === null ) objective.status = 'NOT STARTED';

        filtered = (phase !== null)
            ? (
                (filter !== null)
                  ? objective.status.toLowerCase().replace(" ", "-") === filter && objective.phaseId === _currentPhase.phaseId
                  : objective.phaseId === _currentPhase.phaseId
            )
            : objective.status.toLowerCase().replace(" ", "-") === filter;
      }

      return ( filtered );

    });
    
    setFilteredObjectives(_objectives);
    setLoading(false);
  }, [filter, phase, phases, history, objectives]);

  return (
    <>
      <DashboardPanel>
        <Switch>
          <Route exact path={`${match.path}`}>
            {routeProps => {
              return (
                <ObjectivesMatrix
                  {...routeProps}
                  objectives={filteredObjectives}
                  obFor={obFor}
                />
              );
            }}
          </Route>

          <Route exact path={`${match.path}/list`}>
            {routeProps => {
              return (
                <ObjectivesList
                  {...routeProps}
                  objectives={filteredObjectives}
                  obFor={obFor}
                />
              );
            }}
          </Route>
          <Route exact path={`${match.path}/by-phase`}>
            {routeProps => {
              return (
                <ObjectivesByPhase
                  {...routeProps}
                  objectives={filteredObjectives}
                  obFor={obFor}
                />
              );
            }}
          </Route>
        </Switch>
        <DashboardPanelFooter>
          <NavLink
            exact
            to={`${match.url}`}
            className="m2r lighter-grey"
            activeClassName="text-dark-grey active"
          >
            <FontAwesomeIcon icon={faBorderAll} size="2x" />
          </NavLink>
          <NavLink
            exact
            to={`${match.url}/list`}
            className="m2r lighter-grey"
            activeClassName="text-dark-grey active"
          >
            <FontAwesomeIcon icon={faListUl} size="2x" />
          </NavLink>
          <NavLink
            exact
            to={`${match.url}/by-phase`}
            className="lighter-grey"
            activeClassName="text-dark-grey active"
          >
            <FontAwesomeIcon icon={faColumns} size="2x" />
          </NavLink>
        </DashboardPanelFooter>
      </DashboardPanel>
    </>
  );

  function getForTitle(roadMapId, token, forType, forId) {
    const obForFn =
      forType === "strategicarea"
        ? getStrategicAreaDetails(roadMapId, forId, token)
        : Promise.resolve(null);
  
    return Promise.all([
      obForFn
    ]).then(([_obFor]) => {
      const _obForTitle =
        forType === "strategicarea"
        ? `Objectives limited to Strategic Area - ${_obFor.areaDescription}.`
        : forType === "action" 
          ? `Objectives limited to Action.`
          : null;
  
      return {
        _obForTitle
      };
    });
  }

  function getStrategicAreaDetails(roadMapId, strategicId, token) {
    //TODO: This needs to be in a helper AND we should get the objective by id rather than all and filtering
    if (!strategicId) return null;
  
    return axios
      .get(
        process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/strategicarea`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        const [strategic] = response.data.filter(
          sa => sa.areaId === strategicId
        );
        return strategic || null;
      });
  }

};

export default Objectives;
