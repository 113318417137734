import React, { useState, useContext } from "react";
import "./Login.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import UserContext from "../../context/UserContext";
import CenteredPage from "../Layout/CenteredPage";

// import YouWeLogo from "../../assets/images/youwe_logo_white.png";
// import YouWeLogo from "../../assets/images/youwe_logo_white.png";

const Login = ({ history }) => {
  const userContext = useContext(UserContext);
  const [activity, setActivity] = useState(false);
  const [formValues, setFormValues] = useState({
    userName: "",
    password: ""
  });
  const [formError, setFormError] = useState("");

  const setFormValue = e => {
    let newState = {
      ...formValues,
      [e.target.name]: e.target.value
    };

    setFormValues(() => newState);
  };

  const login = e => {
    e.preventDefault();

    if (!validate()) return;

    setActivity(true);

    setTimeout(() => {
      axios
        .post(process.env.REACT_APP_BASE_URL+"/login", formValues)
        .then(response => {
          const [token, payload] = processResponse(response);
          const roadMaps = payload.roadMaps; // all roadmaps
          const [roadMap] = roadMaps; // first roadmap

          userContext.setCurrentUser({ ...payload });
          localStorage.setItem("successflow.roadmap.auth-token", token);

          if (roadMaps.length > 1) history.push(`/roadmaps`);
          else if (roadMaps.length === 1) history.push(`/roadmap/${roadMap.rmId}/dashboard`);
          else throw new axios.Cancel("User must be associated at least to one Roadmap. \n Please contact the administrator.");
        })
        .catch(e => {
          console.log(e);
          if( axios.isCancel(e) ) setFormError(e.message);
          else setFormError("Login failed. Invalid username and / or password.");
          setActivity(false);
        });
    }, 1);
  };

  const validate = () => {
    if (formValues.userName === "" || formValues.password === "") {
      setFormError("Please enter a valid username and password.");
      return false;
    }

    setFormError("");
    return true;
  };

  return (
    <CenteredPage className="login">
      <div>
        <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" />
      </div>
      <h1 className="login__title">Your digital strategy roadmap</h1>
      <h2 className="login__strap">Where shall we go today?</h2>
      <form className="login__form" onSubmit={login}>
        <input
          name="userName"
          value={formValues.userName}
          type="text"
          placeholder="Email"
          onChange={setFormValue}
          disabled={activity}
        />
        <input
          name="password"
          value={formValues.password}
          type="password"
          placeholder="Password"
          onChange={setFormValue}
          disabled={activity}
        />
        {formError && <div className="login__form-errors">{formError}</div>}
        <button type="submit" disabled={activity}>
          Go!
        </button>
        <a href={process.env.REACT_APP_DB_URL+`/password/reset`} className="forgot_password">Forgot password ?</a>
      </form>
    </CenteredPage>

    // <div className="login">
    //   <header className="login__header">
    //     <img src="/youwe_logo_white.png" alt="Youwe Logo" />
    //   </header>
    //   <main className="login__main">

    //   </main>
    // </div>
  );
};

// custom hook ??
function processResponse(response) {
  const { authorization: authHeader } = response.headers;
  const [, token] = authHeader.split(" ");

  return [token, response.data];
}

export default Login;
