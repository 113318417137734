import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

import "./MatrixMarkerGroup.scss";
import { combineCssClasses } from "../../../utils/utils";

const popoverWidth = 320 + 20; // width of popover + arrow;

const MatrixMarkerGroup = ({ children }) => {
  const [marker, popover] = React.Children.toArray(children);
  const ref = useRef();

  const [popoverPos, setPopoverPos] = useState(null);
  const popoverClasses = combineCssClasses(
    "matrix-marker__detail",
    popoverPos ? `matrix-marker__detail--${popoverPos}` : null
  );

  const togglePopover = () => {
    if (popoverPos) return setPopoverPos(null);

    // basic handling of the position;
    const { right } = ref.current.getBoundingClientRect();
    const pos =
      right + popoverWidth > document.body.clientWidth ? "left" : "right";
    setPopoverPos(pos);
  };

  return (
    <>
      <div
        ref={ref}
        className={
          "matrix-marker" + (popoverPos ? " matrix-marker--active" : "")
        }
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
        onFocus={togglePopover}
        onBlur={togglePopover}
        role="presentation"
      >
        {marker}
        <div className={popoverClasses}>{popover}</div>
      </div>
    </>
  );
};

MatrixMarkerGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default MatrixMarkerGroup;
