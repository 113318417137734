import React, { useContext, useState } from "react";
import "./TopNav.scss";
import UserContext from "../../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import DropDown from "../DropDown";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const TopNav = () => {
  const {
    currentUser,
    currentUser: { roadMaps = [] }
  } = useContext(UserContext);
  const history = useHistory();
  const [showProfile, setShowProfile] = useState(false);

  const logout = () => {
    window.localStorage.removeItem("successflow.roadmap.auth-token");
    history.push("/");
  };

  return (
    <nav className="topnav">
      <div className="topnav__nav-item">
        <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
      </div>
      <div className="topnav__nav-item">
        <FontAwesomeIcon
          icon={faUserAlt}
          title={`Logged in as ${currentUser.userName}`}
          size="lg"
          onClick={() => setShowProfile(!showProfile)}
        />
      </div>
      <DropDown show={showProfile} pos={{ x: 8, y: 42 }}>
        {/* <p className="drop-down__item">
          <Link to="">User Profile</Link>
        </p> */}
        {roadMaps.length > 1 && (
          <p className="drop-down__item">
            <Link to="/roadmaps">Switch Roadmap</Link>
          </p>
        )}
        <p className="drop-down__separator" />
        <p className="drop-down__item">
          <Link to="" onClick={logout}>
            Logout
          </Link>
        </p>
      </DropDown>
    </nav>
  );
};

export default TopNav;
