import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./LeftNavItem.scss";
import { icon } from "@fortawesome/fontawesome-svg-core";

const LeftNavItem = ({ icon, text, path, exact }) => {
  return (
    <div className="leftnav__item">
      <NavLink
        to={path}
        className="light"
        activeClassName="active"
        exact={exact}
      >
        <span className="leftnav__item-icon">
          <FontAwesomeIcon icon={icon} className="fa-2x" />
        </span>
        <span>{text}</span>
      </NavLink>
    </div>
  );
};

LeftNavItem.propTypes = {
  icon: PropTypes.objectOf(icon),
  path: PropTypes.string,
  text: PropTypes.string
};

export default LeftNavItem;
