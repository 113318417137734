import React from "react";
import PropTypes, { string } from "prop-types";

import "./SimpleGrid.scss";

const SimpleGrid = ({ children, columnTemplate }) => {
  return (
    <div
      className="simple-grid"
      style={{ gridTemplateColumns: columnTemplate }}
    >
      {children}
    </div>
  );
};

SimpleGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  columnTemplate: string
};

export default SimpleGrid;
