import React from "react";

import { combineCssClasses } from "../../../utils/utils";

import "./CenteredPage.scss";

const CenteredPage = ({ children, className }) => {
  const cssClasses = combineCssClasses("centered-page", className);

  return (
    <div className={cssClasses}>
      <header className="centered-page__header">
        <img src="/apaze_centered_circle_white.svg" alt="Youwe Logo" className="image" />
      </header>
      <main className="centered-page__main">{children}</main>
    </div>
  );
};

export default CenteredPage;
