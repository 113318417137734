import React from "react";

import "./TacticSummary.scss";

const TacticSummary = ({ title, value }) => {
  return (
    <div className="tactic-summary">
      <div className="tactic-summary__title">{title}</div>
      <div className="tactic-summary__details">
        <p className="tactic-summary__details-title">Percentage complete</p>
        <p className="tactic-summary__details-value">{value}</p>
      </div>
    </div>
  );
};

export default TacticSummary;
