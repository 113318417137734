import React from "react";

import "./DropDown.scss";
import { combineCssClasses } from "../../../utils/utils";

const DropDown = ({ show, pos = { x: 8, y: 48 }, children }) => {
  return (
    <div
      className={combineCssClasses(
        "drop-down",
        show ? "" : "drop-down--hidden"
      )}
      style={{ top: pos.y, right: pos.x }}
    >
      {children}
    </div>
  );
};

export default DropDown;
