import React from "react";
import PropTypes from "prop-types";

import "./DashboardPanel.scss";

const DashboardPanel = ({ children }) => {
  return (
    <section className="dashboard__panel">
      <>{children}</>
    </section>
  );
};

DashboardPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default DashboardPanel;
