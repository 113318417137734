import React, { useState, useEffect } from "react";
import DashboardPanelHeader from "../../../Layout/DashboardPanel/DashboardPanelHeader";
import Loader from "../../../Loader";

import "./ObjectivesMatrix.scss";
import FiltersPanel from "../../FiltersPanel";
import MatrixMarkerGroup from "../../../Layout/MatrixMarkerGroup/MatrixMarkerGroup";
import StatusMarker from "../../../Layout/StatusMarker";
import SimpleGrid from "../../../Layout/SimpleGrid";
import useSearchParams from "../../../../hooks/useSearchParams";
import { useHistory, useLocation } from "react-router-dom";
import AlertBox from "../../../Layout/AlertBox";

/* NOTE: This is design to work specifically with a 8 x 8 grid split into 4 and then each split into 16 */
/* TODO: Move into a layout component and split each quadrant / segment into a separate component */

const ObjectivesMatrix = ({ objectives, obFor }) => {
  const [grid, setGrid] = useState(null);
  const { phase = null, filter = null } = useSearchParams();
  const location = useLocation();
  const history = useHistory();

  const clearOfType = () => {
    const params = [
      filter ? `filter=${filter}` : null,
      phase ? `phase=${phase}` : null
    ].filter(e => e !== null);
    history.push(
      `${location.pathname}${params.length > 0 ? "?" : ""}${params.join("&")}`
    );
    history.go(0)
  };

  const ObjectivesFor = () => {
    if (obFor)
      return (
        <AlertBox>
          <h4 className="flex-row align-items-centre">
            <span className="flex-grow-1">{obFor}</span>
            <button className="btn btn--alert" onClick={clearOfType}>
              Clear
            </button>
          </h4>
        </AlertBox>
      );

    return null;
  };

  const Header = () => {
    return (
      <DashboardPanelHeader className="flex">
        <h1 className="dashboard__panel-title">
          Objectives - impact vs. effort
        </h1>
        <FiltersPanel currentPhase={phase} />
      </DashboardPanelHeader>
    );
  };

  useEffect(() => {
    if (!objectives) return; // initial state, nothing to render

    const hSegmentCount = 8,
      vSegmentCount = 8;

    // build basic layout
    const _quadrants = buildSegments(8, 8);
    objectives.forEach(objective => {
      let _quadrant = `${objective.impact > vSegmentCount / 2 ? "t" : "b"}${
        objective.effort > hSegmentCount / 2 ? "r" : "l"
      }`;
      let _segment = `i${objective.impact}_e${objective.effort}`;
      _quadrants[_quadrant][_segment].push(objective);
    });

    const _matrix = buildInitialMatrix(_quadrants);

    // create segments array per quadrant to ensure ordering of segment
    for (let imp = 8; imp > 0; imp--) {
      for (let eff = 1; eff <= 8; eff++) {
        let _k = `${imp > 4 ? "t" : "b"}${eff > 4 ? "r" : "l"}`;
        _matrix.quadrants[_k].segments.push(_quadrants[_k][`i${imp}_e${eff}`]);
      }
    }

    setGrid(_matrix);
  }, [objectives]);

  if (!grid)
    return (
      <>
        <Header />
        <Loader>Loading objectives...</Loader>
      </>
    );

  if (grid)
    return (
      <>
        <Header />
        <ObjectivesFor />
        <div className="matrix">
          {Object.keys(grid.quadrants).map(quadrant => {
            return (
              <div key={quadrant} className="matrix__quadrant">
                <div
                  className={`matrix__quadrant-title matrix__quadrant-title--${quadrant}`}
                >
                  {grid.quadrants[quadrant].label}
                </div>
                {grid.quadrants[quadrant].segments.map((segment, i) => {
                  return (
                    <div key={i} className="matrix__quadrant-sector">
                      {segment.length > 0 ? (
                        <MatrixMarkerGroup>
                          <span>{segment.length}</span>
                          <>
                            {segment.map((objective, i) => {

                              let status = (objective.status !== null)? objective.status : 'Not Started';
                              let moscow = (objective.moscow !== null)? objective.moscow.substring(0, 1) : '';

                              return (
                                <SimpleGrid key={i} columnTemplate="35px 1fr">
                                  <StatusMarker
                                    title={status}
                                    status={status.toLowerCase()}
                                    unadorned
                                  >
                                    {moscow}
                                  </StatusMarker>
                                  <span className="text--light text--small">
                                    {objective.objectiveDescription}
                                  </span>
                                </SimpleGrid>
                              );
                            })}
                          </>
                        </MatrixMarkerGroup>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <div className="axis-label axis-label--x">{grid.axisLabels.x}</div>
          <div className="axis-label axis-label--y">{grid.axisLabels.y}</div>
        </div>
      </>
    );
};

function buildSegments(vSegmentCount, hSegmentCount) {
  const _quadrants = {
    tl: {},
    tr: {},
    bl: {},
    br: {}
  };

  for (let vCount = 1; vCount <= vSegmentCount; vCount++) {
    for (let hCount = 1; hCount <= hSegmentCount; hCount++) {
      let _k = `${vCount > vSegmentCount / 2 ? "t" : "b"}${
        hCount > hSegmentCount / 2 ? "r" : "l"
      }`;
      _quadrants[_k][`i${vCount}_e${hCount}`] = [];
    }
  }

  return _quadrants;
}

function buildInitialMatrix() {
  return {
    quadrants: {
      tl: {
        label: "Quick wins",
        segments: []
      },
      tr: {
        label: "Consider, resource and plan",
        segments: []
      },
      bl: {
        label: "Could do",
        segments: []
      },
      br: {
        label: "Don't do",
        segments: []
      }
    },
    axisLabels: {
      x: "Effort",
      y: "Impact"
    }
  };
}

export default ObjectivesMatrix;
