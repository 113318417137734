import React, { useContext, useState, useEffect } from "react";

import "./ActionsByPhase.scss";
import DashboardPanelHeader from "../../../Layout/DashboardPanel/DashboardPanelHeader";

import StatusPill from "../../../Layout/StatusPill";
import FiltersPanel from "../../FiltersPanel";
import RowFlowLayout from "../../../Layout/RowFlowLayout/RowFlowLayout";
import Loader from "../../../Loader";
import RoadMapContext from "../../../../context/RoadMapContext";
import PhasedLayout from "../../../Layout/PhasedLayout";
import PhasedLayoutColumn from "../../../Layout/PhasedLayout/PhasedLayoutColumn";
import PhasedLayoutHeader from "../../../Layout/PhasedLayout/PhasedLayoutHeader/PhasedLayoutHeader";
import PhasedLayoutContent from "../../../Layout/PhasedLayout/PhasedLayoutContent";
import useSearchParams from "../../../../hooks/useSearchParams";
import Popover from "../../../Layout/Popover/Popover";

const Header = () => {
  return (
    <DashboardPanelHeader className="flex">
      <h1 className="dashboard__panel-title">Actions by phase</h1>
      <FiltersPanel showPhases={false} />
    </DashboardPanelHeader>
  );
};

const Loading = () => (
  <>
    <Header />
    <Loader centered>Loading actions...</Loader>
  </>
);

const ActionsByPhase = ({ actions }) => {
  const { phases } = useContext(RoadMapContext);
  const [loading, setLoading] = useState(true);
  const [filteredActions, setFilteredActions] = useState(null);
  const { filter = null } = useSearchParams();
  const [pos, setPos] = useState(null);
  const [activeAction, setActiveAction] = useState(null);

  useEffect(() => {
    if (filter === null) {
      setFilteredActions(actions);
      setLoading(false);
      return;
    }

    const _actions = actions.filter(action => {
      return (
        filter === null ||
        action.stDesc.toLowerCase().replace(" ", "-") === filter
      );
    });

    setFilteredActions(_actions);
    setLoading(false);
  }, [filter, actions]);

  if (!phases || !filteredActions || loading) return <Loading />;

  const showPopover = (objective, e) => {
    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    setPos({ left, top, width, height, right: left + width });
    setActiveAction(objective);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveAction(null);
  };

  return (
    <>
      <Header />
      <PhasedLayout>
        {phases.map(phase => {
          return (
            <PhasedLayoutColumn key={phase.phaseId}>
              <PhasedLayoutHeader phase={phase} />
              <PhasedLayoutContent>
                <RowFlowLayout columnCount={3} reversed={true}>
                  {filteredActions.map(action => {
                    return action.phaseId === phase.phaseId ? (
                      <StatusPill
                        onMouseEnter={showPopover.bind(null, action)}
                        onMouseLeave={hidePopover}
                        key={action.id}
                        status={action.stDesc}
                        className="help"
                      >
                        {action.id}
                      </StatusPill>
                    ) : null;
                  })}
                </RowFlowLayout>
              </PhasedLayoutContent>
            </PhasedLayoutColumn>
          );
        })}
        <Popover pos={pos}>
          <p className="text--small">{activeAction && activeAction.action}</p>
          {
            <p className="text--small m1t">
              {activeAction && activeAction.narrative}
            </p>
          }
        </Popover>
      </PhasedLayout>
    </>
  );
};

export default ActionsByPhase;
