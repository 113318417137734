import React, { useEffect, useState } from "react";
import axios from "axios";

import LeftNav from "../Layout/LeftNav";
import TopNav from "../Layout/TopNav";

import "./Dashboard.scss";

import RoadmapOverview from "./RoadmapOverview";
import Loader from "../Loader";
import DashboardOverview from "./DashboardOverview";
import Actions from "./Actions";

import useScrollToTop from "../../hooks/useScrollToTop";

import { Switch, Route } from "react-router-dom";
import Objectives from "./Objectives";
import StrategicAreas from "./StrategicAreas";
import Tactics from "./Tactics";
import Situation from "./Situation";

import RoadMapContext from "../../context/RoadMapContext";

const Dashboard = ({ match, history }) => {
  const { roadMapId } = match.params;
  const [loading, updateLoading] = useState(true);
  const [overviewData, updateOverviewData] = useState({});
  const [roadmapData, updateRoadmapData] = useState(null);

  useScrollToTop();

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");

    if (!token) return history.push("/login");

    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/dashboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        updateOverviewData(response.data);
        updateRoadmapData({
          details: response.data.PC1,
          phases: response.data.PC10
        });
        updateLoading(false);
      })
      .catch(err => {
        console.log(err);
        history.push("/login");
      });
  }, [history, roadMapId]);

  if (loading) {
    return <Loader centered>Initialising Roadmap ...</Loader>;
  } else {
    return (
      <RoadMapContext.Provider value={roadmapData}>
        <div className="dashboard">
          <TopNav></TopNav>
          <LeftNav></LeftNav>
          <div className="dashboard__content">
            <RoadmapOverview data={overviewData.PC2} />
            <Switch>
              <Route exact path={`${match.path}`}>
                {routeProps => {
                  return (
                    <DashboardOverview
                      overviewData={overviewData}
                      {...routeProps}
                    />
                  );
                }}
              </Route>
              <Route path={`${match.path}/actions`} component={Actions} />
              <Route path={`${match.path}/objectives`} component={Objectives} />
              <Route
                path={`${match.path}/strategic-areas`}
                component={StrategicAreas}
              />
              <Route path={`${match.path}/tactics`} component={Tactics} />
              <Route path={`${match.path}/situation`} component={Situation} />
            </Switch>
          </div>
        </div>
      </RoadMapContext.Provider>
    );
  }
};

export default Dashboard;
