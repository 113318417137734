import React, { useState, useEffect } from "react";

import TableLayout from "../../../Layout/TableLayout";
import TableHeader from "../../../Layout/TableLayout/TableHeader";
import TableRow from "../../../Layout/TableLayout/TableRow";
import TableCell from "../../../Layout/TableLayout/TableCell";
import StatusPill from "../../../Layout/StatusPill";
import { Link } from "react-router-dom";

const columnsTemplate = "1fr 100px 100px";

const TacticDetails = ({ match, tactics }) => {
  console.log(match.params);
  console.log(match);
  const [filteredTactics, setFilteredTactics] = useState([]);

  useEffect(() => {
    const areaId = match.params.id;
    const _tactics = tactics.filter(
      tactic => tactic.strategicAreaId === areaId
    );

    setFilteredTactics(_tactics);
  }, [tactics, match]);

  const TableContent = () => {
    if (filteredTactics.length === 0)
      return (
        <TableRow columns={columnsTemplate} className="p2t p2b">
          <TableCell
            align="left"
            className="table__row--span-all table__row--centered p2t p2b"
          >
            No associated tactics
          </TableCell>
        </TableRow>
      );

    return filteredTactics.map((row, i) => {
      return (
        <TableRow key={i} columns={columnsTemplate}>
          <TableCell align="left">{row.tacticGroupDescription}</TableCell>
          <TableCell align="centre">
            <Link
              to={`/roadmap/${match.params.roadMapId}/dashboard/actions?forType=tacticgroup&forId=${row.tacticGroupId}`}
            >
              {row.noOfActions}
            </Link>
          </TableCell>
          <TableCell align="stretch">
            <StatusPill status={row.status}>{row.status}</StatusPill>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <TableLayout>
      <TableHeader columns={columnsTemplate}>
        <TableCell>Description</TableCell>
        <TableCell align="centre">Actions</TableCell>
        <TableCell align="centre">Status</TableCell>
      </TableHeader>
      <TableContent />
    </TableLayout>
  );
};

export default TacticDetails;
