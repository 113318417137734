import React from "react";

import "./RowFlowLayout.scss";
import { combineCssClasses } from "../../../utils/utils";

const RowFlowLayout = ({
  children,
  columnCount = 4,
  reversed = false,
  highlightAlternate = false,
  size = null
}) => {
  const rows = asRows(React.Children.toArray(children), columnCount, reversed);
  const layoutRows = reversed ? rows.reverse() : rows;

  return (
    <div className="row-flow">
      {layoutRows.map((row, rowIndex) => {
        const rowClasses = combineCssClasses(
          "row-flow__row",
          [
            size ? `row-flow--spacing-${size}` : "",
            `row-flow__row--${columnCount}col`,
            highlightAlternate && rowIndex % 2 === 0 ? " highlight" : ""
          ]
            .filter(v => v !== "")
            .join(" ")
        );
        return (
          <div className={rowClasses} key={rowIndex}>
            {row.map(r => r)}
          </div>
        );
      })}
    </div>
  );
};

function asRows(actions, columnCount, reversed) {
  let columns = [];

  // setup
  for (let i = 0; i < columnCount; i++) {
    columns.push([]);
  }

  for (let x = 0, currCol = 0; x < actions.length; x++) {
    columns[currCol++].push(actions[x]);

    currCol = currCol === columnCount ? 0 : currCol;
  }

  const rows = [];
  for (let r = 0; r < Math.ceil(actions.length / columnCount); r++) {
    let row = [];

    if (reversed) {
      for (let c = columnCount - 1; c >= 0; c--) {
        row.push(columns[c][r]);
      }
    } else {
      for (let c = 0; c < columnCount; c++) {
        row.push(columns[c][r]);
      }
    }
    rows.push(row);
  }

  return rows;
}

export default RowFlowLayout;
