import React, { useEffect, useState } from "react";

import "./StrategicAreas.scss";
import DashboardPanelHeader from "../../Layout/DashboardPanel/DashboardPanelHeader";
import DashboardPanel from "../../Layout/DashboardPanel";
import TableLayout from "../../Layout/TableLayout";
import TableHeader from "../../Layout/TableLayout/TableHeader/TableHeader";
import TableCell from "../../Layout/TableLayout/TableCell";
import TableRow from "../../Layout/TableLayout/TableRow/TableRow";
import StatusPill from "../../Layout/StatusPill";
import LabelPill from "../../Layout/LabelPill";
import axios from "axios";
import Loader from "../../Loader";
import { Link } from "react-router-dom";

const columnsTemplate = "1fr 100px 100px 100px 100px";

const Header = () => (
  <DashboardPanelHeader className="flex">
    <h1 className="dashboard__panel-title">Strategic areas</h1>
  </DashboardPanelHeader>
);

const StrategicAreas = ({ history, match }) => {
  const { roadMapId } = match.params;
  const [loading, setLoading] = useState(true);
  const [strategicAreas, setStrategicAreas] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/roadmap/${roadMapId}/strategicarea`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        setStrategicAreas(response.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        history.push("/login");
      });
  }, [history, roadMapId]);

  if (loading)
    return (
      <DashboardPanel>
        <Header />
        <Loader>Loading ...</Loader>
      </DashboardPanel>
    );

  return (
    <DashboardPanel>
      <Header />

      <TableLayout>
        <TableHeader columns={columnsTemplate}>
          <TableCell>Description</TableCell>
          <TableCell align="centre">Objectives Involved</TableCell>
          <TableCell align="centre">Actions Involved</TableCell>
          <TableCell align="centre">Importance</TableCell>
          <TableCell align="centre">Status</TableCell>
        </TableHeader>
        {strategicAreas.map((strategicArea, i) => {
          return (
            <TableRow key={i} columns={columnsTemplate} className="p2t p2b">
              <TableCell align="left">
                <div className="flex flex-column">
                  <p className="m1b">
                    <strong>{strategicArea.areaDescription}</strong>
                  </p>
                  <p>{strategicArea.stratAreaNarrative}</p>
                </div>
              </TableCell>
              <TableCell align="centre">
                { strategicArea.objectivesInvolved > 0
                  ? <Link
                      to={`/roadmap/${match.params.roadMapId}/dashboard/objectives/list?forType=strategicarea&forId=${strategicArea.areaId}`}
                    >
                      {strategicArea.objectivesInvolved}
                    </Link>
                  : 0}
              </TableCell>
              <TableCell align="centre">
                { strategicArea.actionsInvolved 
                  ? <Link
                      to={`/roadmap/${match.params.roadMapId}/dashboard/actions?forType=strategicarea&forId=${strategicArea.areaId}`}
                    >
                      {strategicArea.actionsInvolved}
                    </Link>
                  : 'Not Set'}
              </TableCell>
              <TableCell align="centre">
                <LabelPill>{strategicArea.importance}</LabelPill>
              </TableCell>
              <TableCell align="stretch">
                <StatusPill status={strategicArea.status}>
                  {strategicArea.status}
                </StatusPill>
              </TableCell>
            </TableRow>
          );
        })}
      </TableLayout>
    </DashboardPanel>
  );
};

export default StrategicAreas;
