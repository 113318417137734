import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import axios from "axios";

import "./Gantt.scss";
//import { func } from "prop-types";

const Gantt = ({ history }) => {

  const [ganttChart, setGantt] = useState(true);

  const roadMapId = window.location.href.split('/')[4];
  const startDate = new Date(2020, 3, 1);
  const endDate = new Date(startDate.getTime());
  endDate.setMonth(startDate.getMonth() + 3);
  endDate.setDate(0);

  const dayCount = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;

  let days = [];
  let months = [];

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    axios
      .get(
        process.env.REACT_APP_BASE_URL + `/roadmap/${roadMapId}/gantt`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        setGantt(response.data);
      })
      .catch(err => {
        console.log(err);
        history.push("/login");
      });

  }, [history, roadMapId]);

  const mappedData = {};
  const mappedStartDate = [];
  const mappedEndDate = [];
  const mappedName = [];

  Object.keys(ganttChart).forEach(
    a => {

      mappedStartDate.push(new Date(ganttChart[a].planStart));
      mappedEndDate.push(new Date(ganttChart[a].planEnd));

      if (!mappedData[ganttChart[a].stratArea]) {
        mappedData[ganttChart[a].stratArea] = {
          name: `\xa0${ganttChart[a].stratArea}`,
          type: 'Strategic Area',
          start: ganttChart[a].planStart,//new Date().setDate(beginning.getDate()-30)
          end: ganttChart[a].planEnd,
          tactics: {
            [ganttChart[a].tacticGroup]: {
              name: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].tacticGroup}`,
              type: 'Tactic',
              start: ganttChart[a].planStart,
              end: ganttChart[a].planEnd,
              actions: [{
                name: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].action}`,
                start: ganttChart[a].planStart,
                end: ganttChart[a].planEnd,
                percentComplete: ganttChart[a].percentageComplete,
                type: 'Action'
              }]
            }
          }
        }
        mappedName.push(ganttChart[a].stratArea);

      } else {
        if (!mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup]) {
          mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup] = {
            name: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].tacticGroup}`,
            type: 'Tactic',
            start: ganttChart[a].planStart,
            end: ganttChart[a].planEnd,
            actions: [{
              name: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].action}`,
              start: ganttChart[a].planStart,
              end: ganttChart[a].planEnd,
              percentComplete: ganttChart[a].percentageComplete,
              type: 'Action'
            }]
          }

          const thisStart = new Date(ganttChart[a].planStart);
          const thisEnd = new Date(ganttChart[a].planEnd);
          const stratAreaStart = new Date(mappedData[ganttChart[a].stratArea].planStart);
          const stratAreaEnd = new Date(mappedData[ganttChart[a].stratArea].planEnd);

          if (thisStart < stratAreaStart) {
            mappedData[ganttChart[a].stratArea].start = ganttChart[a].planStart
          }
          if (thisEnd > stratAreaEnd) {
            mappedData[ganttChart[a].stratArea].end = ganttChart[a].planEnd
          }

          mappedName.push(`\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].tacticGroup}`);

        } else {
          mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup].actions.push({
            name: `\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].action}`,
            start: ganttChart[a].planStart,
            end: ganttChart[a].planEnd,
            percentComplete: ganttChart[a].percentageComplete,
            type: 'Action'
          });

          const thisStart = new Date(ganttChart[a].planStart);
          const thisEnd = new Date(ganttChart[a].planEnd);
          const tacticStart = new Date(mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup].start);
          const tacticEnd = new Date(mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup].end);
          const stratAreaStart = new Date(mappedData[ganttChart[a].stratArea].start);
          const stratAreaEnd = new Date(mappedData[ganttChart[a].stratArea].end);


          if (thisStart < tacticStart) {
            mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup].start = ganttChart[a].planStart
          }

          if (thisEnd > tacticEnd) {
            mappedData[ganttChart[a].stratArea].tactics[ganttChart[a].tacticGroup].end = ganttChart[a].planEnd
          }

          if (thisStart < stratAreaStart) {
            mappedData[ganttChart[a].stratArea].start = ganttChart[a].planStart
          }
          if (thisEnd > stratAreaEnd) {
            mappedData[ganttChart[a].stratArea].end = ganttChart[a].planEnd
          }

          mappedName.push(`\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0${ganttChart[a].action}`);
        }
      }
    }

  );

  let p = 0
  const fn = ((o, arr) => {
    return Object.keys(o).reduce((acc, k, i) => {
      if (o[k].tactics) {
        p = acc.length;
        return fn(o[k].tactics, [...acc, {
          id: p,
          type: 'Strategic Area',
          name: o[k].name,
          start: o[k].start,
          end: o[k].end
        }])
      }

      if (o[k].actions) {
        const tactic = {
          id: acc.length,
          parent: p,
          type: 'Tactic',
          name: o[k].name,
          start: o[k].start,
          end: o[k].end
        }
        const actions = o[k].actions.map(({ name, start, end, percentComplete }, i1) => {
          return { id: acc.length + i1 + 1, parent: acc.length, type: 'Action', name, start, end, percentComplete }
        })
        return [...acc, tactic, ...actions];
      }
    }, arr);
  })

  const builtData = fn(mappedData, []);

  const ganttChartRows = builtData.map(
    ({ id, name, type, start, end, parent, percentComplete }) => ([id.toString(), name, type, new Date(start), new Date(end), null, percentComplete === undefined ? '0' : percentComplete, parent ? parent.toString() : null])
  );

  let chartHeight = 35 * ganttChartRows.length + 100;

  let ganttChartColumn =
    [
      { type: 'string', label: 'Task ID' },
      { type: 'string', label: 'Task Name' },
      { type: 'string', label: 'Resource' },
      { type: 'date', label: 'Start Date' },
      { type: 'date', label: 'End Date' },
      { type: 'number', label: 'Duration' },
      { type: 'number', label: 'Percent Complete' },
      { type: 'string', label: 'Dependencies' },
    ];

  for (let i = 0; i < dayCount; i++) {
    let currentDate = new Date(startDate.getTime());
    currentDate.setDate(currentDate.getDate() + i);
    const isMonthEnd = isLastDayOfMonth(currentDate);
    days.push({
      date: currentDate,
      isMonthEnd,
      isWeekEnd: currentDate.getDay() === 0
    });

    if (isFirstDayOfMonth(currentDate)) {
      months.push({
        start: i + 1,
        length: i + daysInMonth(currentDate) + 1
      });
    }
  }
  
  const dummyGanttChartRows = builtData.map(
    ({ id, name, type, start, end, parent, percentComplete }) => ([id.toString(), name, type, new Date(start), new Date(end), null, percentComplete === undefined ? '0' : percentComplete, parent ? parent.toString() : null])
  );

  let longestNameRow = dummyGanttChartRows.sort( (a, b) =>
    {return a[1].length - b[1].length}
  );
  
  let getLongestName = '';

  if( longestNameRow[longestNameRow.length-1] !== undefined && longestNameRow[longestNameRow.length-1] !== null)
    getLongestName = longestNameRow[longestNameRow.length-1][1];
  
  return (
    <div>
      <div id="gantt__header" style={{
        padding: '10px 10px 0px 10px'
      }}>
        <Chart
          chartType="Gantt"
          options={{
            height: '100px',
            gantt: {
              labelMaxWidth: Math.max(...(mappedName.map(el => el.length))) * 6,
              trackHeight: 20,
              barHeight: 18,
              palette: [{
                "color": "#909bab",
                "dark": "#909bab",
                "light": "#909bab"
              }],
              innerGridHorizLine: {
                stroke: '#666',
                strokeWidth: 1,
              },
              innerGridTrack: { fill: '#909bab' },
              innerGridDarkTrack: { fill: '909bab' },
            },
          }}
          chartEvents={[
            {
              eventName: 'ready',
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();

                var container = document.getElementById('gantt__header');
                var svg = container.querySelector('SVG');
                var gs = svg.querySelectorAll('g');
                var labels = gs[7].querySelectorAll('text');

                labels.forEach(l => {
                  l.setAttribute('style', 'opacity: 0;')
                });

                google.visualization.events.addListener(chart, "onmouseover", e => {
                  var container = document.getElementById('gantt__header');
                  var svg = container.querySelector('SVG');
                  var gs = svg.querySelectorAll('g');
                  gs[9].style.display = "none";
                  var labels = gs[7].querySelectorAll('text');
                  labels.forEach(l => {
                    l.setAttribute('style', 'opacity: 0;')
                  });
                });
                
                google.visualization.events.addListener(chart, "select", e => {
                  var container = document.getElementById('gantt__header');
                  var svg = container.querySelector('SVG');
                  var gs = svg.querySelectorAll('g');
                  gs[9].style.display = "none";
                  var labels = gs[7].querySelectorAll('text');
                  labels.forEach(l => {
                    l.setAttribute('style', 'opacity: 0;')
                  });
                });

                google.visualization.events.addListener(chart, "error", err => {
                  // check error
                  console.log(err.id, err.message);
                  // remove error
                  google.visualization.errors.removeError(err.id);
                });
              }
            }
          ]}
          data={[
            [
              { type: 'string', label: 'Task ID' },
              { type: 'string', label: 'Task Name' },
              { type: 'string', label: 'Resource' },
              { type: 'date', label: 'Start Date' },
              { type: 'date', label: 'End Date' },
              { type: 'number', label: 'Duration' },
              { type: 'number', label: 'Percent Complete' },
              { type: 'string', label: 'Dependencies' },
            ],
            [
              'InitialDate',
              // '' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0' +
              // '',
              getLongestName,
              null,
              new Date(Math.min.apply(null, mappedStartDate)),
              new Date(Math.max.apply(null, mappedEndDate)),
              null,
              100,
              null,
            ]
          ]}
        />
      </div>

      <div className="gantt">
        <div className="gantt__layout" style={{ position: 'relative', height: '65vh' }}>
          <div id="chart_div">
            <Chart
              height={'100%'}
              chartType="Gantt"
              loader={<div>Loading Chart</div>}
              options={{
                height: chartHeight,
                gantt: {
                  labelMaxWidth: Math.max(...(mappedName.map(el => el.length))) * 6,
                  trackHeight: 35,
                  barHeight: 23,
                  palette: [
                    { 
                      "color" : "#7a6b9c",
                      "dark" : "#7a6b9c",
                      "light" : "#7a6b9c"
                    },
                    { 
                      "color" : "#7798CE",
                      "dark" : "#7798CE",
                      "light" : "#7798CE"
                    },
                    { 
                      "color" : "#ca9d9b",
                      "dark" : "#ca9d9b",
                      "light" : "#ca9d9b"
                    }
                  ],
                  innerGridTrack: { fill: '#E9F2FA' },
                  innerGridDarkTrack: { fill: '#FFFFFF' },
                  labelStyle: {
                    fontName: 'Arial',
                    fontSize: 14,
                    color: '#757575',
                    textAlign: 'left'
                  },
                  innerGridHorizLine: {
                    strokeWidth: 0,
                  },
                  arrow: {
                    angle: 100,
                    length: 0,
                    width: 0,
                    color: '#b2aca4',
                    radius: 0,
                    spaceAfter: 0
                  },
                  criticalPathEnabled: false,
                },
              }}
              data={[ganttChartColumn, ...ganttChartRows]}
              chartEvents={[
                {
                  eventName: 'ready',
                  callback: ({ chartWrapper, google }) => {
                    const chart = chartWrapper.getChart();

                    var container = document.getElementById('chart_div');
                    var svg = container.querySelector('SVG');
                    var gs = svg.querySelectorAll('g');
                    var labels = gs[7].querySelectorAll('text');

                    labels.forEach(l => {

                      var str = l.innerHTML;
                        var count = (str.match(/&nbsp;/g) || []).length;
                        if( count === 1 ) l.setAttribute('style', 'font-weight: 800');
                        else if ( count === 8 ) l.setAttribute('style', 'font-weight: 600');
                        else {
                          l.setAttribute('style', 'font-weight: 400; font-size: 13px');
                          l.setAttribute('fill', '#d59d95');
                        }

                      //l.setAttribute('fill', 'black');
                      l.setAttribute('x', '300');
                      l.setAttribute('dx', '-300');
                    });

                    google.visualization.events.addListener(chart, "onmouseover", e => {
                      var container = document.getElementById('chart_div');
                      var svg = container.querySelector('SVG');
                      var gs = svg.querySelectorAll('g');
                      var labels = gs[7].querySelectorAll('text');
                      labels.forEach(l => {

                        var str = l.innerHTML;
                        var count = (str.match(/&nbsp;/g) || []).length;
                        if( count === 1 ) l.setAttribute('style', 'font-weight: 800');
                        else if ( count === 8 ) l.setAttribute('style', 'font-weight: 600');
                        else {
                          l.setAttribute('style', 'font-weight: 400; font-size: 13px');
                          l.setAttribute('fill', '#d59d95');
                        }

                        l.setAttribute('x', '300');
                        l.setAttribute('dx', '-300');
                      });
                    });

                    google.visualization.events.addListener(chart, "onmouseout", e => {
                      var container = document.getElementById('chart_div');
                      var svg = container.querySelector('SVG');
                      var gs = svg.querySelectorAll('g');
                      var labels = gs[7].querySelectorAll('text');
                      labels.forEach(l => {

                        var str = l.innerHTML;
                        var count = (str.match(/&nbsp;/g) || []).length;
                        if( count === 1 ) l.setAttribute('style', 'font-weight: 800');
                        else if ( count === 8 ) l.setAttribute('style', 'font-weight: 600');
                        else {
                          l.setAttribute('style', 'font-weight: 400; font-size: 13px');
                          l.setAttribute('fill', '#d59d95');
                        }

                        l.setAttribute('x', '300');
                        l.setAttribute('dx', '-300');
                      });
                    });

                    google.visualization.events.addListener(chart, "select", e => {
                      var container = document.getElementById('chart_div');
                      var svg = container.querySelector('SVG');
                      var gs = svg.querySelectorAll('g');
                      var labels = gs[7].querySelectorAll('text');
                      labels.forEach(l => {
                        
                        var str = l.innerHTML;
                        var count = (str.match(/&nbsp;/g) || []).length;
                        if( count === 1 ) l.setAttribute('style', 'font-weight: 800');
                        else if ( count === 8 ) l.setAttribute('style', 'font-weight: 600');
                        else {
                          l.setAttribute('style', 'font-weight: 400; font-size: 13px');
                          l.setAttribute('fill', '#d59d95');
                        }
                        
                        l.setAttribute('x', '300');
                        l.setAttribute('dx', '-300');
                      });
                    });

                    google.visualization.events.addListener(chart, "error", err => {
                      // check error
                      console.log(err.id, err.message);
                      // remove error
                      google.visualization.errors.removeError(err.id);
                    });
                  }
                }
              ]}

            />

          </div>
        </div>
      </div>
    </div>
  );
};

const isLastDayOfMonth = d => {
  const tomorrow = new Date(new Date(d.getTime()).setDate(d.getDate() + 1));
  return d.getMonth() < tomorrow.getMonth();
};
const isFirstDayOfMonth = d => {
  return d.getDate() === 1;
};
const daysInMonth = d => {
  const firstDay = new Date(new Date(d.getTime()).setDate(1));
  const lastDay = new Date(
    new Date(firstDay).setMonth(firstDay.getMonth() + 1)
  );
  lastDay.setDate(0);

  return (lastDay - firstDay) / (1000 * 60 * 60 * 24) + 1;
};

export default Gantt;
