import React from "react";
import PropTypes from "prop-types";

import "./StatusMarker.scss";
import { combineCssClasses } from "../../../utils/utils";

const StatusMarker = ({ children, status, title, unadorned = false }) => {
  const _status = status.toLowerCase().replace(" ", "-");
  const cssClasses = combineCssClasses(
    ["status-marker", `status-marker--${_status}`].join(" "),
    unadorned ? "status-marker--unadorned" : ""
  );
  return (
    <div className={cssClasses} title={title}>
      {children}
    </div>
  );
};

StatusMarker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default StatusMarker;
