import React from "react";
import DashboardPanel from "../../Layout/DashboardPanel";
import DashboardPanelHeader from "../../Layout/DashboardPanel/DashboardPanelHeader";
import ObjectivesOverview from "../ObjectivesOverview";
import ActionsDueTable from "../ActionsDueTable";
import StrategicAreaRating from "../StrategicAreaRating";

const DashboardOverview = ({ overviewData, match }) => {
  return (
    <>
      <DashboardPanel>
        <DashboardPanelHeader>
          <h1 className="dashboard__panel-title">Objectives overview</h1>
        </DashboardPanelHeader>
        <ObjectivesOverview data={overviewData.PC3} />
      </DashboardPanel>
      <section className="flex flex-grow flex-grow-columns dashboard__panel--group">
        <DashboardPanel>
          <DashboardPanelHeader>
            <h1 className="dashboard__panel-title">Actions due this week</h1>
          </DashboardPanelHeader>
          <ActionsDueTable actions={overviewData.PC4} showOverdue />
        </DashboardPanel>
        <DashboardPanel>
          <DashboardPanelHeader>
            <h1 className="dashboard__panel-title">Actions due next week</h1>
          </DashboardPanelHeader>
          <ActionsDueTable actions={overviewData.PC5} />
        </DashboardPanel>
      </section>
      <DashboardPanel>
        <DashboardPanelHeader>
          <h1 className="dashboard__panel-title">Strategic area rating</h1>
        </DashboardPanelHeader>
        <StrategicAreaRating data={overviewData.PC6} match={match} />
      </DashboardPanel>
    </>
  );
};

export default DashboardOverview;
