import React from "react";
import "./PhasedLayoutHeader.scss";

const PhasedLayoutHeader = ({ phase }) => {
  return (
    <div className="phase__column-header">
      <div>{phase.phaseName}</div>
      <div className="text--light text--small">
        {new Date(phase.phaseStartDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })} - {" "}
        {new Date(phase.phaseEndDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
      </div>
    </div>
  );
};

export default PhasedLayoutHeader;
