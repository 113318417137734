import React from "react";

import "./InfoPanelDetail.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoPanelDetail = ({ icon, text, value }) => {
  return (
    <div className="flex">
      <FontAwesomeIcon
        icon={icon}
        style={{ marginTop: "1px", marginRight: "10px" }}
      />
      <p className="flex-grow-1 text--light">{text}</p>
      <p className="text--bold">{value}</p>
    </div>
  );
};

export default InfoPanelDetail;
