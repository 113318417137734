import React from "react";
import PropTypes from "prop-types";

import "./StatusPill.scss";
import { combineCssClasses } from "../../../utils/utils";

const StatusPill = ({
  children,
  status,
  className,
  onMouseEnter,
  onMouseLeave
}) => {
  if(status === null) status = 'NOT STARTED';
  const _status = status.toLowerCase().replace(" ", "-");
  const cssClasses = combineCssClasses(
    `status-pill status-pill--${_status}`,
    className
  );

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cssClasses}
    >
      {children}
    </div>
  );
};

StatusPill.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

export default StatusPill;
