import React from "react";
import PropTypes from "prop-types";

import "./LabelPill.scss";

const LabelPill = ({ children }) => {
  return <div className={"label-pill"}>{children}</div>;
};

LabelPill.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default LabelPill;
