import React, { useState, useEffect } from "react";
import axios from "axios";

const BrandLogo = ({ history, match }) => {
  const [logo, setLogo] = useState(true);
  const rmId = window.location.href.split('/')[4];

  useEffect(() => {
    const token = localStorage.getItem("successflow.roadmap.auth-token");
    if (!token) history.push("/login");

    axios
      .get(
        process.env.REACT_APP_BASE_URL+`/roadmap/${rmId}/companyLogo`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        setLogo(response.data[0]);
      })
      .catch(err => {
        console.log(err);
        history.push("/login");
      });
  }, [history, rmId]);
  
  if(logo.coLogo !== null){
    return <img src={process.env.REACT_APP_DB_URL+`/media/`+logo.coLogo} alt="Brand Logo" />;
  } else if (logo.coLogoUrl !== null) {
    return <img src={logo.coLogoUrl} alt="Brand Logo" />;
  } else {
    return <img src={`/youwe_logo_white.png`} alt="Brand Logo" />;
  }  
};

export default BrandLogo;

