import React, { useState } from "react";
import ReactPaginate from 'react-paginate';

import "./ObjectivesList.scss";
import DashboardPanelHeader from "../../../Layout/DashboardPanel/DashboardPanelHeader";
import TableLayout from "../../../Layout/TableLayout";
import TableHeader from "../../../Layout/TableLayout/TableHeader/TableHeader";
import TableRow from "../../../Layout/TableLayout/TableRow/TableRow";
import TableCell from "../../../Layout/TableLayout/TableCell";
import StatusPill from "../../../Layout/StatusPill";
import Loader from "../../../Loader";
import { useHistory, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

import FiltersPanel from "../../FiltersPanel";
import useSearchParams from "../../../../hooks/useSearchParams";
import Popover from "../../../Layout/Popover/Popover";
import { Link } from "react-router-dom";
import AlertBox from "../../../Layout/AlertBox";

const columnsTemplate = "1fr 100px 100px 100px 100px";

const ObjectivesList = ({ objectives, match, obFor}) => {
  const { phase = null, filter = null } = useSearchParams();
  const [pos, setPos] = useState(null);
  const [activeObjective, setActiveObjective] = useState(null);
  
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const clearOfType = () => {
    const params = [
      filter ? `filter=${filter}` : null,
      phase ? `phase=${phase}` : null
    ].filter(e => e !== null);
    history.push(
      `${location.pathname}${params.length > 0 ? "?" : ""}${params.join("&")}`
    );
    history.go(0)
  };

  const ObjectivesFor = () => {
    if (obFor)
      return (
        <AlertBox>
          <h4 className="flex-row align-items-centre">
            <span className="flex-grow-1">{obFor}</span>
            <button className="btn btn--alert" onClick={clearOfType}>
              Clear
            </button>
          </h4>
        </AlertBox>
      );

    return null;
  };

  const Header = () => {
    return (
      <DashboardPanelHeader className="flex">
        <h1 className="dashboard__panel-title">Objectives</h1>
        <FiltersPanel currentPhase={phase} />
      </DashboardPanelHeader>
    );
  };

  if (!objectives)
    return (
      <>
        <Header />
        <Loader>Loading objectives...</Loader>
      </>
    );

  const showPopover = (objective, e) => {
    if (!objective.metNarrative) return;

    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    setPos({ left, top, width, height, right: left + width });
    setActiveObjective(objective);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveObjective(null);
  };

  const handlePageClick = (e) => {
      const selectedPage = e.selected;
      setOffset(selectedPage * perPage);
      setCurrentPage(selectedPage);
      objectives = objectives.slice(offset, offset + perPage);

  };

  var pageCount = Math.ceil(objectives.length / perPage);
  objectives = objectives.slice(offset, offset + perPage);
  
  return (
    <>
      <Header />
      <ObjectivesFor />
      <TableLayout>
        <TableHeader columns={columnsTemplate}>
          <TableCell>Description</TableCell>
          <TableCell align="centre">Actions involved</TableCell>
          <TableCell align="centre">MoSCoW priority</TableCell>
          <TableCell align="centre">Status</TableCell>
          <TableCell align="centre">Objective met</TableCell>
        </TableHeader>
        
        {objectives.length > 0 &&
          objectives.map((objective, i) => {

            let status = (objective.status !== null)? objective.status : 'Not Started';
            let tacticsInvolved = (objective.tacticsInvolved !== null)? objective.tacticsInvolved : 0;
            let moscow = (objective.moscow !== null)? objective.moscow : 'NOT SET';

            return (
              <TableRow
                key={`${i}_${objective.objectiveDescription}`}
                columns={columnsTemplate}
              >
                <TableCell>{objective.objectiveDescription}</TableCell>
                <TableCell align="centre">
                  <Link
                    to={`/roadmap/${match.params.roadMapId}/dashboard/actions?forType=objective&forId=${objective.objectiveId}`}
                  >
                    {tacticsInvolved}
                  </Link>
                </TableCell>
                <TableCell align="centre">{moscow}</TableCell>
                <TableCell align="stretch">
                  <StatusPill status={status}>
                    {status}
                  </StatusPill>
                </TableCell>
                <TableCell align="centre" className="text-dark-grey">
                  <div
                    className="help"
                    onMouseEnter={showPopover.bind(null, objective)}
                    onMouseLeave={hidePopover}
                  >
                    <FontAwesomeIcon
                      icon={getObjectiveMetIcon(objective.objectiveMet)}
                      size="lg"
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableLayout>
      <Popover pos={pos}>
        <p className="text--small">
          {activeObjective && activeObjective.metNarrative}
        </p>
      </Popover>
      <ReactPaginate
        previousLabel={"prev"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </>
  );
};

function getObjectiveMetIcon(objectiveMet) {
  if (objectiveMet === 0) return faCircle;

  if (objectiveMet === 1) return faCheckCircle;

  return faTimesCircle;
}

export default ObjectivesList;
