import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import "./App.scss";

import { Base64 } from "js-base64";

import UserContext from "./context/UserContext";
import RoadmapSelector from "./components/RoadmapSelector";

function App() {
  const [currentUser, setCurrentUser] = useState(getAuthenticatedUser());

  useEffect(() => {});

  /* TODO: create HOC or hook for protecting routes */
  return (
    <div className="app">
      <UserContext.Provider value={{ currentUser, setCurrentUser }}>
        <Router>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/roadmaps" component={RoadmapSelector} />
            <Route path="/roadmap/:roadMapId/dashboard" component={Dashboard} />
            <Route path="*">
              {props => {
                if (!currentUser) {
                  return <Redirect to="/login" {...props} />;
                } else {
                  const [roadMap] = currentUser.roadMaps;

                  return (
                    <Redirect
                      to={`/roadmap/${roadMap.rmId}/dashboard`}
                      {...props}
                    />
                  );
                }
              }}
            </Route>
          </Switch>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

/* TODO: Move to helper functions */
function getAuthenticatedUser() {
  const token = localStorage.getItem("successflow.roadmap.auth-token");

  if (!token) return null;

  const [, payload] = token.split(".");

  return JSON.parse(Base64.decode(payload));
}

export default App;
