import React, { useState } from "react";
import TableLayout from "../../Layout/TableLayout";

import "./ActionsDueTable.scss";
import StatusPill from "../../Layout/StatusPill";
import TableHeader from "../../Layout/TableLayout/TableHeader/TableHeader";
import TableRow from "../../Layout/TableLayout/TableRow/TableRow";
import TableCell from "../../Layout/TableLayout/TableCell";

import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Popover from "../../Layout/Popover/Popover";

const ActionsDueTable = ({ actions, showOverdue = false }) => {
  const columnTemplate = showOverdue ? "20px 1fr 100px 70px" : "20px 1fr 100px";
  const [pos, setPos] = useState(null);
  const [activeAction, setActiveAction] = useState(null);

  const showPopover = (objective, e) => {
    // console.log(e.target.offsetHeight);
    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    setPos({ left, top, width, height, right: left + width });
    setActiveAction(objective);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveAction(null);
  };

  return (
    <>
      <TableLayout>
        <TableHeader columns={columnTemplate}>
          <TableCell></TableCell>
          <TableCell>Description</TableCell>
          <TableCell align="centre">Status</TableCell>
          {showOverdue && <TableCell align="centre">Overdue</TableCell>}
        </TableHeader>

        {actions.map(action => {
          return (
            <TableRow key={action.taDesc} columns={columnTemplate}>
              <TableCell className="text-theme">
                <div
                  className="help"
                  onMouseEnter={showPopover.bind(null, action)}
                  onMouseLeave={hidePopover}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
              </TableCell>
              <TableCell>{action.taDesc}</TableCell>
              <TableCell align="stretch">
                <StatusPill status={action.stDesc}>{action.stDesc}</StatusPill>
              </TableCell>
              {showOverdue && (
                <TableCell align="centre">
                  {action.daysOverdue > 0 ? `${action.daysOverdue} days` : ""}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableLayout>
      <Popover pos={pos}>
        <p className="text--small">
          {(activeAction && activeAction.narrative) || "No narrative set"}
        </p>
      </Popover>
    </>
  );
};

ActionsDueTable.propTypes = {
  actions: PropTypes.array.isRequired,
  showOverdue: PropTypes.bool
};

export default ActionsDueTable;
