import React, { useContext } from "react";

import "./FiltersPanel.scss";
import { useLocation, useHistory, Link } from "react-router-dom";
import PhaseSelector from "../../Layout/PhaseSelector/PhaseSelector";

import RoadMapContext from "../../../context/RoadMapContext";
import useSearchParams from "../../../hooks/useSearchParams";
import { combineCssClasses } from "../../../utils/utils";

// TODO: This needs refactoring
const FiltersPanel = ({ currentPhase, showPhases = true }) => {
  const location = useLocation();
  const history = useHistory();
  const { phases } = useContext(RoadMapContext);
  const { filter, phase, ...otherParams } = useSearchParams();

  const changePhase = p => {
    const params = [
      ...Object.keys(otherParams).map(k => `${k}=${otherParams[k]}`),
      filter ? `filter=${filter}` : null,
      p.target.value ? `phase=${p.target.value}` : null
    ].filter(e => e !== null);

    const _loc = `${location.pathname}${
      params.length > 0 ? "?" : ""
    }${params.join("&")}`;

    history.push(_loc);
  };

  const getLink = _filter => {
    const params = [
      ...Object.keys(otherParams).map(k => `${k}=${otherParams[k]}`),
      _filter ? `filter=${_filter}` : null,
      phase ? `phase=${phase}` : null
    ].filter(e => e !== null);

    return `${location.pathname}${params.length > 0 ? "?" : ""}${params.join(
      "&"
    )}`;
  };

  return (
    <div className="status-filter">
      {showPhases && (
        <PhaseSelector
          phases={phases}
          selectedPhase={currentPhase}
          changePhase={changePhase}
        />
      )}
      <div>
        <div className="status-filter__filters flex">
          <div className="m2r">Filter by:</div>
          <div className="m2r">
            <Link
              to={`${getLink("not-started")}`}
              className={combineCssClasses(
                "light text--light",
                filter === "not-started" ? "active text--normal" : ""
              )}
            >
              Not started
            </Link>
          </div>
          <div className="m2r">
            <Link
              to={`${getLink("in-progress")}`}
              className={combineCssClasses(
                "light text--light",
                filter === "in-progress" ? "active text--normal" : ""
              )}
            >
              In progress
            </Link>
          </div>
          <div className="m2r">
            <Link
              to={`${getLink("completed")}`}
              className={combineCssClasses(
                "light text--light",
                filter === "completed" ? "active text--normal" : ""
              )}
            >
              Completed
            </Link>
          </div>
          <div className="m2r">
            <Link
              to={`${getLink("blocked")}`}
              className={combineCssClasses(
                "light text--light",
                filter === "blocked" ? "active text--normal" : ""
              )}
            >
              Blocked
            </Link>
          </div>
          <div>
            <Link
              to={`${getLink("")}`}
              className={combineCssClasses(
                "light text--light",
                !filter ? "active text--normal" : ""
              )}
            >
              All
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersPanel;
