import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./StrategicAreaRating.scss";
import LabelPill from "../../Layout/LabelPill";
import RowFlowLayout from "../../Layout/RowFlowLayout/RowFlowLayout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Popover from "../../Layout/Popover/Popover";

/* TODO: remove styles */
const StrategicAreaRating = ({ data, match }) => {
  const [ratings, setRatings] = useState(null);
  const [pos, setPos] = useState(null);
  const [activeMessage, setActiveMessage] = useState(null);

  useEffect(() => {
    setRatings(
      data.sort((a, b) =>
        a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0
      )
    );
  }, [data]);

  if (!ratings) return <></>;

  const showPopover = (objective, e) => {
    const left = e.currentTarget.offsetLeft;
    const top = e.currentTarget.offsetTop;
    const width = e.currentTarget.offsetWidth;
    const height = e.currentTarget.offsetHeight;
    console.log(left, top);
    setPos({ left, top, width, height, right: left + width });
    setActiveMessage(objective);
  };
  const hidePopover = () => {
    setPos(null);
    setActiveMessage(null);
  };

  return (
    <>
      <RowFlowLayout columnCount={3} highlightAlternate>
        {ratings.map((rating, idx) => {
          return (
            <div key={idx} className="p1">
              <Link
                to={`${match.url}/tactics/${rating.id}`}
                className="flex align-items-centre"
              >
                <div className="flex-grow-1">
                  {rating.areaTitle}
                  {rating.warningFlag === "Y" ? (
                    <span
                      className="help"
                      onMouseEnter={showPopover.bind(null, rating)}
                      onMouseLeave={hidePopover}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color={"red"}
                        className="m1l"
                      />
                    </span>
                  ) : null}
                </div>
                <div>
                  <LabelPill>{rating.priority}</LabelPill>
                </div>
              </Link>
            </div>
          );
        })}
      </RowFlowLayout>
      <Popover pos={pos}>
        <p className="text--small">
          {activeMessage && activeMessage.warningMessage}
        </p>
      </Popover>
    </>
  );
};

StrategicAreaRating.propTypes = {
  data: PropTypes.array
};

export default StrategicAreaRating;
