import React from "react";
import { combineCssClasses } from "../../../utils/utils";

import "./InfoPanel.scss";

const InfoPanel = ({ Icon, children, TopContent }) => {
  return (
    <div className="info-panel">
      <div
        className={combineCssClasses(
          "info-panel__top",
          children ? "info-panel__top--with-seperator" : null
        )}
      >
        {Icon}
        <div className="info-panel__top-content">{TopContent}</div>
      </div>
      {children}
    </div>
  );
};

export default InfoPanel;
